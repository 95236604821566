import styles from './styles.module.css';
import { InitialConfigType } from '@lexical/react/LexicalComposer';
import { ListItemNode, ListNode } from '@lexical/list';
import { LinkNode, AutoLinkNode } from '@lexical/link';
import { LineBreakNode } from 'lexical';

export const initialEditorConfig: InitialConfigType = {
	namespace: 'wysiwyg',
	theme: {
		text: {
			bold: styles.bold,
			italic: styles.italic,
			underline: styles.underline,
			strikethrough: styles.strikethrough,
			base: styles.base,
		},
		paragraph: styles.paragraph,
		link: styles.link,
		list: {
			ul: styles.ul,
			ol: styles.ol,
			listitem: styles.listitem,
			olDepth: [styles.olDepth2, styles.olDepth3, styles.olDepth4, styles.olDepth5],
		},
	},
	nodes: [ListNode, ListItemNode, LinkNode, AutoLinkNode, LineBreakNode],
	onError: (error) => {
		console.error(error);
	},
};
