import { useAuth } from '../../auth/useAuth';

type Result = {
	readonly pass: boolean;
};

const useAuthGuard = (): Result => {
	const auth = useAuth();

	if (!auth.isSignedIn) {
		return { pass: false };
	}

	return { pass: true };
};

export { useAuthGuard };
