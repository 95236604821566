import * as Sentry from '@sentry/react';

const sentryEnvironment = import.meta.env.REACT_APP_SENTRY_ENVIRONMENT ?? 'local';

const sentry: Sentry.BrowserOptions = {
	enabled: import.meta.env.REACT_APP_SENTRY_DSN != null,
	dsn: import.meta.env.REACT_APP_SENTRY_DSN,
	debug: sentryEnvironment === 'local',
	environment: sentryEnvironment,
	release: import.meta.env.REACT_APP_SENTRY_RELEASE,
	integrations: [
		Sentry.extraErrorDataIntegration(),
		Sentry.captureConsoleIntegration({
			levels: ['warn', 'error', 'assert'],
		}),
		Sentry.browserTracingIntegration(),
	],
};
const config = {
	environment: import.meta.env.REACT_APP_ENVIRONMENT ?? 'local',
	sentry,

	apigw: {
		url: import.meta.env.REACT_APP_APIGW_URL ?? 'http://localhost:43210/graphql',
	},

	contember: {
		loginToken: import.meta.env.REACT_APP_CONTEMBER_LOGIN_TOKEN ?? '1111111111111111111111111111111111111111',
	},

	minPasswordLength: parseInt(import.meta.env.REACT_APP_MIN_PASSWORD_LENGTH ?? '6', 10),

	userpilotAppToken: import.meta.env.REACT_APP_USERPILOT_APP_TOKEN ?? '',
} as const;

export default config;
