import { createContext, FunctionComponent, ReactNode, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { getEnvironmentDomain } from '../utils/getEnvironmentDomain';

type AuthState =
	| {
			readonly isSignedIn: false;
	  }
	| {
			readonly isSignedIn: true;
			readonly token: string;
	  };

type AuthValue = {
	readonly state: AuthState;
	readonly setSignedIn: (token: string) => void;
	readonly setSignedOut: () => void;
};

type AuthProviderProps = {
	readonly children: ReactNode;
};

const defaultAuthState: AuthState = {
	isSignedIn: false,
};

const defaultAuthValue: AuthValue = {
	state: defaultAuthState,
	setSignedIn: () => {},
	setSignedOut: () => {},
};

const AuthContext = createContext<AuthValue>(defaultAuthValue);

const useAuth = (): AuthState => {
	const { state } = useContext(AuthContext);

	return state;
};

const AuthProvider: FunctionComponent<AuthProviderProps> = ({ children }) => {
	const [{ token }, setToken, removeToken] = useCookies(['token']);
	const [state, setState] = useState<AuthState>(token ? { isSignedIn: true, token } : defaultAuthState);

	useEffect(() => {
		if (token) {
			setState({
				isSignedIn: true,
				token,
			});
		} else {
			removeToken('token', { domain: getEnvironmentDomain() });
			setState({
				isSignedIn: false,
			});
		}
	}, [token, setState, removeToken]);

	return (
		<AuthContext.Provider
			value={{
				state,
				setSignedIn: (token) => {
					setToken('token', token, { domain: getEnvironmentDomain(), sameSite: 'lax' });
				},
				setSignedOut: () => {
					removeToken('token', { domain: getEnvironmentDomain() });
					setState({
						isSignedIn: false,
					});
				},
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export { useAuth, AuthContext, AuthProvider };
