/*
 * This plugin is taken directly from the Lexical github repo.
 * It is used to display the link editor when the anchor is within the link.
 * It has been adjusted to use MUI components.
 * The source of this plugin can be found here: https://github.com/facebook/lexical/tree/main/packages/lexical-playground/src/plugins/FloatingLinkEditorPlugin
 */

import { $isAutoLinkNode, $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $findMatchingParent, mergeRegister } from '@lexical/utils';
import {
	$getSelection,
	$isRangeSelection,
	BaseSelection,
	COMMAND_PRIORITY_CRITICAL,
	COMMAND_PRIORITY_HIGH,
	COMMAND_PRIORITY_LOW,
	KEY_ESCAPE_COMMAND,
	LexicalEditor,
	SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { Dispatch, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { IconButton, Link, OutlinedInput, Stack, styled } from '@mui/material';
import { sanitizeUrl } from '../utils/url';
import { getSelectedNode, setFloatingElemPositionForLinkEditor } from '../utils/helpers';
import { Check, Close, Delete, Edit } from '@mui/icons-material';
import { getNightOrDayPalette } from '../../../utils/getNightOrDayPalette';

const LinkEditor = styled('div')<{ isLink: boolean }>`
	display: ${({ isLink }) => (isLink ? 'flex' : 'none')};
	position: absolute;
	align-items: center;
	justify-content: space-between;
	top: 0;
	left: 0;
	z-index: 10;
	max-width: 400px;
	background-color: ${({ theme }) => theme.palette[getNightOrDayPalette(theme)][0]};
	box-shadow: ${({ theme }) => theme.customShadows.z1};
	padding: 8px 12px;
	border-radius: 8px;
	transition: opacity 0.5s;
	will-change: transform;
`;

const LinkView = styled('div')`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 15px;
	font-size: 15px;
	color: ${({ theme }) => theme.palette[getNightOrDayPalette(theme)][900]};
	border: 0;
	outline: 0;
	position: relative;
	font-family: inherit;
`;

function FloatingLinkEditor({
	editor,
	isLink,
	setIsLink,
	anchorElem,
}: {
	editor: LexicalEditor;
	isLink: boolean;
	setIsLink: Dispatch<boolean>;
	anchorElem: HTMLElement;
}): JSX.Element | null {
	const editorRef = useRef<HTMLDivElement | null>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const [linkUrl, setLinkUrl] = useState('');
	const [editedLinkUrl, setEditedLinkUrl] = useState('');
	const [isEditMode, setEditMode] = useState(false);
	const [lastSelection, setLastSelection] = useState<BaseSelection | null>(null);

	const updateLinkEditor = useCallback(() => {
		const selection = $getSelection();
		if ($isRangeSelection(selection)) {
			const node = getSelectedNode(selection);
			const parent = node.getParent();
			if ($isLinkNode(parent)) {
				setLinkUrl(parent.getURL());
			} else if ($isLinkNode(node)) {
				setLinkUrl(node.getURL());
			} else {
				setLinkUrl('');
			}
		}
		const editorElem = editorRef.current;
		const nativeSelection = window.getSelection();
		const activeElement = document.activeElement;

		if (editorElem === null) {
			return;
		}

		const rootElement = editor.getRootElement();

		if (
			selection !== null &&
			nativeSelection !== null &&
			rootElement !== null &&
			rootElement.contains(nativeSelection.anchorNode) &&
			editor.isEditable()
		) {
			const domRect: DOMRect | undefined = nativeSelection.focusNode?.parentElement?.getBoundingClientRect();
			if (domRect) {
				domRect.y += 40;
				setFloatingElemPositionForLinkEditor(domRect, editorElem, anchorElem);
			}
			setLastSelection(selection);
		} else if (!activeElement || activeElement.className !== 'link-input') {
			if (rootElement !== null) {
				setFloatingElemPositionForLinkEditor(null, editorElem, anchorElem);
			}
			setLastSelection(null);
			setEditMode(false);
			setLinkUrl('');
		}

		return true;
	}, [anchorElem, editor]);

	useEffect(() => {
		const scrollerElem = anchorElem.parentElement;

		const update = () => {
			editor.getEditorState().read(() => {
				updateLinkEditor();
			});
		};

		window.addEventListener('resize', update);

		if (scrollerElem) {
			scrollerElem.addEventListener('scroll', update);
		}

		return () => {
			window.removeEventListener('resize', update);

			if (scrollerElem) {
				scrollerElem.removeEventListener('scroll', update);
			}
		};
	}, [anchorElem.parentElement, editor, updateLinkEditor]);

	useEffect(() => {
		return mergeRegister(
			editor.registerUpdateListener(({ editorState }) => {
				editorState.read(() => {
					updateLinkEditor();
				});
			}),

			editor.registerCommand(
				SELECTION_CHANGE_COMMAND,
				() => {
					updateLinkEditor();
					return true;
				},
				COMMAND_PRIORITY_LOW,
			),
			editor.registerCommand(
				KEY_ESCAPE_COMMAND,
				() => {
					if (isLink) {
						setIsLink(false);
						return true;
					}
					return false;
				},
				COMMAND_PRIORITY_HIGH,
			),
		);
	}, [editor, updateLinkEditor, setIsLink, isLink]);

	useEffect(() => {
		editor.getEditorState().read(() => {
			updateLinkEditor();
		});
	}, [editor, updateLinkEditor]);

	useEffect(() => {
		if (isEditMode && inputRef.current) {
			inputRef.current.focus();
		}
	}, [isEditMode]);

	const monitorInputInteraction = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			handleLinkSubmission();
		} else if (event.key === 'Escape') {
			event.preventDefault();
			setEditMode(false);
		}
	};

	const handleLinkSubmission = () => {
		if (lastSelection !== null) {
			if (linkUrl !== '') {
				editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl(editedLinkUrl));
			}
			setEditMode(false);
		}
	};

	return (
		<LinkEditor ref={editorRef} isLink={isLink}>
			{!isLink ? null : isEditMode ? (
				<>
					<OutlinedInput
						ref={inputRef}
						value={editedLinkUrl}
						onChange={(event) => {
							setEditedLinkUrl(event.target.value);
						}}
						size={'small'}
						onKeyDown={(event) => {
							monitorInputInteraction(event);
						}}
					/>
					<Stack ml={2} gap={1} flexShrink={0} flexGrow={0} direction={'row'}>
						<IconButton
							type={'button'}
							tabIndex={0}
							onClick={() => {
								setEditMode(false);
							}}
						>
							<Close />
						</IconButton>

						<IconButton type={'button'} tabIndex={0} onClick={handleLinkSubmission}>
							<Check />
						</IconButton>
					</Stack>
				</>
			) : (
				<LinkView>
					<Link
						href={sanitizeUrl(linkUrl)}
						sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '20ch' }}
						target={'_blank'}
						rel={'noopener noreferrer'}
					>
						{linkUrl}
					</Link>
					<Stack ml={2} gap={1} flexShrink={0} flexGrow={0} direction={'row'}>
						<IconButton
							type={'button'}
							tabIndex={0}
							onClick={() => {
								setEditedLinkUrl(linkUrl);
								setEditMode(true);
							}}
						>
							<Edit />
						</IconButton>
						<IconButton
							type={'button'}
							tabIndex={0}
							onClick={() => {
								editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
							}}
						>
							<Delete />
						</IconButton>
					</Stack>
				</LinkView>
			)}
		</LinkEditor>
	);
}

function useFloatingLinkEditorToolbar(editor: LexicalEditor, anchorElem: HTMLElement): JSX.Element | null {
	const [activeEditor, setActiveEditor] = useState(editor);
	const [isLink, setIsLink] = useState(false);

	const updateToolbar = useCallback(() => {
		const selection = $getSelection();
		if ($isRangeSelection(selection)) {
			const node = getSelectedNode(selection);
			const linkParent = $findMatchingParent(node, $isLinkNode);
			const autoLinkParent = $findMatchingParent(node, $isAutoLinkNode);

			// We don't want this menu to open for auto links.
			if (linkParent != null && autoLinkParent == null) {
				setIsLink(true);
			} else {
				setIsLink(false);
			}
		}
	}, []);

	useEffect(() => {
		return mergeRegister(
			editor.registerUpdateListener(({ editorState }) => {
				editorState.read(() => {
					updateToolbar();
				});
			}),
			editor.registerCommand(
				SELECTION_CHANGE_COMMAND,
				(_payload, newEditor) => {
					updateToolbar();
					setActiveEditor(newEditor);
					return false;
				},
				COMMAND_PRIORITY_CRITICAL,
			),
		);
	}, [editor, updateToolbar]);

	return createPortal(
		<FloatingLinkEditor editor={activeEditor} isLink={isLink} anchorElem={anchorElem} setIsLink={setIsLink} />,
		anchorElem,
	);
}

export default function FloatingLinkEditorPlugin({
	anchorElem = document.body,
}: {
	anchorElem?: HTMLDivElement | HTMLElement;
}): JSX.Element | null {
	const [editor] = useLexicalComposerContext();
	return useFloatingLinkEditorToolbar(editor, anchorElem);
}
