import { lazy } from 'react';

// project import
import GuestGuard from '../utils/route-guard/GuestGuard';
import CommonLayout from './CommonLayout';
import Loadable from '../components/Loadable';

// render - login
const Login = Loadable(lazy(() => import('../guestPages/login')));
const AuthPasswordResetRequest = Loadable(lazy(() => import('../guestPages/password-reset-request')));
const AuthPasswordResetCheckEmail = Loadable(lazy(() => import('../guestPages/password-reset-check-email')));
const AuthPasswordResetFinish = Loadable(lazy(() => import('../guestPages/password-reset-finish')));
const AuthSetPassword = Loadable(lazy(() => import('../guestPages/set-password')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
	path: '/auth',
	children: [
		{
			path: '/auth',
			element: (
				<GuestGuard>
					<CommonLayout />
				</GuestGuard>
			),
			children: [
				{
					path: 'login',
					element: <Login />,
				},
				{
					path: 'password-reset/request',
					element: <AuthPasswordResetRequest />,
				},
				{
					path: 'password-reset/check-email',
					element: <AuthPasswordResetCheckEmail />,
				},
				{
					path: 'password-reset/finish/:token',
					element: <AuthPasswordResetFinish />,
				},
				{
					path: 'set-password/:token',
					element: <AuthSetPassword />,
				},
			],
		},
	],
};

export default LoginRoutes;
