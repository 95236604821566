import { FC, SyntheticEvent } from 'react';

// material-ui
import { Alert, Button, Fade, Grow, Slide, SlideProps } from '@mui/material';
import MuiSnackbar from '@mui/material/Snackbar';

// project-import
import IconButton from './IconButton';
import { useSnackbar } from '../../store/snackbar';

// assets
import { CloseOutlined } from '@ant-design/icons';

// types
import { FormattedMessage } from 'react-intl';

// animation function
function TransitionSlideLeft(props: SlideProps) {
	return <Slide {...props} direction={'left'} />;
}

function TransitionSlideUp(props: SlideProps) {
	return <Slide {...props} direction={'up'} />;
}

function TransitionSlideRight(props: SlideProps) {
	return <Slide {...props} direction={'right'} />;
}

function TransitionSlideDown(props: SlideProps) {
	return <Slide {...props} direction={'down'} />;
}

function GrowTransition(props: SlideProps) {
	return <Grow {...props} />;
}

type KeyedObject = {
	[key: string]: string | number | KeyedObject | any;
};

// animation options
const animation: KeyedObject = {
	SlideLeft: TransitionSlideLeft,
	SlideUp: TransitionSlideUp,
	SlideRight: TransitionSlideRight,
	SlideDown: TransitionSlideDown,
	Grow: GrowTransition,
	Fade,
};

// ==============================|| SNACKBAR ||============================== //

const Snackbar: FC = () => {
	const { state: snackbar, closeSnackbar } = useSnackbar();
	const { anchorOrigin, alert, close, message, open, transition, variant } = snackbar;

	const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		closeSnackbar();
	};

	return (
		<>
			{/* default snackbar */}
			{variant === 'default' && (
				<MuiSnackbar
					anchorOrigin={anchorOrigin}
					open={open}
					autoHideDuration={6000}
					onClose={handleClose}
					message={message}
					TransitionComponent={animation[transition]}
					action={
						<>
							<Button color={'secondary'} size={'small'} onClick={handleClose}>
								<FormattedMessage id={'snackbar.undo-button'} />
							</Button>
							<IconButton size={'small'} color={'inherit'} onClick={handleClose} sx={{ mt: 0.25 }}>
								<CloseOutlined />
							</IconButton>
						</>
					}
				/>
			)}

			{/* alert snackbar */}
			{variant === 'alert' && (
				<MuiSnackbar
					TransitionComponent={animation[transition]}
					anchorOrigin={anchorOrigin}
					open={open}
					autoHideDuration={6000}
					onClose={handleClose}
				>
					<Alert
						variant={'filled'}
						severity={alert.severity}
						color={alert.color}
						onClose={(e) => close && handleClose(e)}
						sx={{ width: '100%', color: 'white' }}
					>
						{message}
					</Alert>
				</MuiSnackbar>
			)}
		</>
	);
};

export default Snackbar;
