import { $createLineBreakNode, $createParagraphNode, ElementNode, LineBreakNode, ParagraphNode, RangeSelection, TextNode } from 'lexical';
import { $isAtNodeEnd } from '@lexical/selection';
import { TextMatchTransformer, ElementTransformer, TextFormatTransformer } from '@lexical/markdown';

export const getSelectedNode = (selection: RangeSelection): TextNode | ElementNode => {
	const anchor = selection.anchor;
	const focus = selection.focus;
	const anchorNode = selection.anchor.getNode();
	const focusNode = selection.focus.getNode();
	if (anchorNode === focusNode) {
		return anchorNode;
	}
	const isBackward = selection.isBackward();
	if (isBackward) {
		return $isAtNodeEnd(focus) ? anchorNode : focusNode;
	} else {
		return $isAtNodeEnd(anchor) ? anchorNode : focusNode;
	}
};

const VERTICAL_GAP = 10;
const HORIZONTAL_OFFSET = 5;

export const setFloatingElemPositionForLinkEditor = (
	targetRect: DOMRect | null,
	floatingElem: HTMLElement,
	anchorElem: HTMLElement,
	verticalGap: number = VERTICAL_GAP,
	horizontalOffset: number = HORIZONTAL_OFFSET,
): void => {
	const scrollerElem = anchorElem.parentElement;

	if (targetRect === null || !scrollerElem) {
		floatingElem.style.transform = 'translate(-10000px, -10000px)';
		return;
	}

	const floatingElemRect = floatingElem.getBoundingClientRect();
	const anchorElementRect = anchorElem.getBoundingClientRect();
	const editorScrollerRect = scrollerElem.getBoundingClientRect();

	let top = targetRect.top - verticalGap;
	let left = targetRect.left - horizontalOffset;

	if (top < editorScrollerRect.top) {
		top += floatingElemRect.height + targetRect.height + verticalGap * 2;
	}

	if (left + floatingElemRect.width > editorScrollerRect.right) {
		left = editorScrollerRect.right - floatingElemRect.width - horizontalOffset;
	}

	top -= anchorElementRect.top;
	left -= anchorElementRect.left;

	floatingElem.style.transform = `translate(${left}px, ${top}px)`;
};

export const BR: TextMatchTransformer = {
	dependencies: [LineBreakNode],
	export: (node) => {
		return null;
	},
	importRegExp: /<br\s?\/?>/,
	regExp: /<br\s?\/?>/,
	replace: (textNode) => {
		const parentNode = textNode.getParent();
		if (parentNode?.getChildrenSize() === 1) {
			textNode.remove();
		} else {
			textNode.replace($createLineBreakNode());
		}
	},
	trigger: '',
	type: 'text-match',
};

export const LINE_BREAK_FIX: ElementTransformer = {
	dependencies: [ParagraphNode],
	export: (node) => {
		return null;
	},
	regExp: /^$/,
	replace: (_node, nodes, _, isImport) => {
		if (isImport && nodes.length === 1) {
			nodes[0].replace($createParagraphNode());
		}
	},
	type: 'element',
};

export const UNDERLINE: TextFormatTransformer = {
	format: ['underline'],
	tag: '<ins>',
	type: 'text-format',
};
