import { FC, PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';

const SITE_NAME = 'Out of Dark';

const HelmetWrapper: FC<PropsWithChildren> = ({ children }) => {
	return (
		<>
			<Helmet titleTemplate={`${SITE_NAME} | $s`} defaultTitle={SITE_NAME} />
			{children}
		</>
	);
};

export { HelmetWrapper };
