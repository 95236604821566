import { atom, useAtom } from 'jotai';

// types
import { SnackbarProps } from '../types/snackbar';
import { useCallback } from 'react';

const initialState: SnackbarProps = {
	action: false,
	open: false,
	message: 'Note archived',
	anchorOrigin: {
		vertical: 'bottom',
		horizontal: 'right',
	},
	variant: 'default',
	alert: {
		color: 'success',
		variant: 'filled',
		severity: 'info',
	},
	transition: 'Fade',
	close: true,
	actionButton: false,
	maxStack: 3,
	dense: false,
	iconVariant: 'usedefault',
};

const snackbarAtom = atom<SnackbarProps>(initialState);

type ReturnType = Readonly<{
	state: SnackbarProps;
	openSnackbar: (props: Partial<SnackbarProps>) => void;
	closeSnackbar: () => void;
}>;

export const useSnackbar = (): ReturnType => {
	const [state, setState] = useAtom(snackbarAtom);

	const openSnackbar = useCallback(
		({ open, message, anchorOrigin, variant, alert, transition, close, actionButton }: Partial<SnackbarProps>) => {
			setState({
				...state,
				action: !state.action,
				open: open || initialState.open,
				message: message || initialState.message,
				anchorOrigin: anchorOrigin || initialState.anchorOrigin,
				variant: variant || initialState.variant,
				alert: {
					color: alert?.color || initialState.alert.color,
					variant: alert?.variant || initialState.alert.variant,
					severity: alert?.color || initialState.alert.color,
				},
				transition: transition || initialState.transition,
				close: close === false ? close : initialState.close,
				actionButton: actionButton || initialState.actionButton,
			});
		},
		[state, setState],
	);

	const closeSnackbar = () => {
		setState({ ...state, open: false });
	};

	return { state, openSnackbar, closeSnackbar };
};
