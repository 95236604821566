import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import * as qs from 'qs';
import config from '../config/config';
import { getEnvironmentDomain } from '../utils/getEnvironmentDomain';

const stateSchema = z.object({
	organizationSlug: z.string(),
	pathname: z.string(),
});

const googleResponseSchema = z.object({
	code: z.string(),
	scope: z.string(),
});

export const useHandleGoogleAuthRedirect = (state: string | undefined): null | undefined => {
	const [searchParams] = useSearchParams();

	if (state) {
		const parsedState = stateSchema.parse(qs.parse(state));
		const parsedGoogleParams = googleResponseSchema.parse(qs.parse(searchParams.toString()));
		let link = `${window.location.protocol}//${parsedState.organizationSlug}.${getEnvironmentDomain()}${parsedState.pathname}?${qs.stringify(parsedGoogleParams)}`;
		if (config.environment === 'local') {
			link = `http://localhost:3000${parsedState.pathname}?${qs.stringify(parsedGoogleParams)}`;
		}
		window.location.href = link;
		return null;
	}
};
