import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_DEFAULT_PATH } from '../../config';

import { GuardProps } from '../../types/auth';
import { useAuth } from '../../auth/useAuth';
import { useQueryClient } from '@tanstack/react-query';

const GuestGuard: FC<GuardProps> = ({ children }: GuardProps) => {
	const auth = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const client = useQueryClient();

	useEffect(() => {
		client.clear();
		if (auth.isSignedIn) {
			navigate(location.state.from || APP_DEFAULT_PATH, {
				state: {
					from: '',
				},
				replace: true,
			});
		}
	}, [auth, navigate, location, client]);

	return children;
};

export default GuestGuard;
