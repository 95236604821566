// types
import { DefaultConfigProps, MenuOrientation, ThemeDirection, ThemeMode } from './types/config';

// ==============================|| THEME CONSTANT  ||============================== //

export const APP_DEFAULT_PATH = '/';

// ==============================|| THEME CONFIG  ||============================== //

const config: DefaultConfigProps = {
	fontFamily: `'Public Sans', sans-serif`,
	i18n: 'en',
	menuOrientation: MenuOrientation.VERTICAL,
	miniDrawer: false,
	container: true,
	mode: ThemeMode.LIGHT,
	presetColor: 'default',
	themeDirection: ThemeDirection.LTR,
};

export default config;
