import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';

/**
 * Adds additional properties and methods to Dayjs instance in run-time.
 * Import of the plugins also extend Dayjs TypeScript definition.
 */
const initDayjs = (): void => {
	dayjs.extend(weekday);
	dayjs.extend(localeData);
	dayjs.extend(localizedFormat);
};

export { initDayjs };
