import { lazy } from 'react';

import Loadable from '../components/Loadable';
import AuthGuard from '../utils/route-guard/AuthGuard';
import { RouteObject } from 'react-router';

const SwitcherPage = Loadable(lazy(() => import('../switcher/SwitcherPage')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes: RouteObject = {
	path: '/',
	children: [
		{
			path: '/',
			element: (
				<AuthGuard>
					<SwitcherPage />
				</AuthGuard>
			),
		},
	],
};

export default MainRoutes;
