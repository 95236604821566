import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { FC, useEffect } from 'react';
import { $convertFromMarkdownString } from '@lexical/markdown';
import { TRANSFORMERS } from '../Editor';

type Props = Readonly<{
	state: string | null;
}>;

/**
 * This plugin is for updating editor state once it has been initialized.
 * Editor after initialization does not accept any changes to its state.
 */
export const UpdateReadonlyContentPlugin: FC<Props> = ({ state }) => {
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		editor.update(() => {
			// Update readonly editor content on state changes
			if (!editor.isEditable() && state !== null) {
				$convertFromMarkdownString(state, TRANSFORMERS);
			}
		});
	}, [editor, state]);

	return null;
};
