/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n\tquery announcement_announcementQuery {\n\t\tannouncements {\n\t\t\tid\n\t\t\ttype\n\t\t\tmessage\n\t\t}\n\t}\n": types.Announcement_AnnouncementQueryDocument,
    "\n\tmutation auth_signIn($email: String!, $password: String!) {\n\t\tsignIn(email: $email, password: $password, expiration: 3600) {\n\t\t\tok\n\t\t\tresult {\n\t\t\t\ttoken\n\t\t\t}\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t}\n\t\t}\n\t}\n": types.Auth_SignInDocument,
    "\n\tmutation auth_signOut {\n\t\tsignOut {\n\t\t\tok\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t}\n\t\t}\n\t}\n": types.Auth_SignOutDocument,
    "\n\tquery getMe {\n\t\tme {\n\t\t\tid\n\t\t\tname\n\t\t\temail\n\t\t\torganizations {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tslug\n\t\t\t\tslugAlias\n\t\t\t\tslugPreferred\n\t\t\t\tmetadata {\n\t\t\t\t\tstatus\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n": types.GetMeDocument,
    "\n\tmutation auth_resetPassword($token: String!, $password: String!) {\n\t\tresetPassword(token: $token, password: $password) {\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t}\n\t\t}\n\t}\n": types.Auth_ResetPasswordDocument,
    "\n\tmutation auth_expiredTokenResetPasswordRequest($email: String!) {\n\t\tcreateResetPasswordRequest(email: $email) {\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t}\n\t\t}\n\t}\n": types.Auth_ExpiredTokenResetPasswordRequestDocument,
    "\n\tmutation auth_createResetPassword($email: String!) {\n\t\tcreateResetPasswordRequest(email: $email) {\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t}\n\t\t}\n\t}\n": types.Auth_CreateResetPasswordDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery announcement_announcementQuery {\n\t\tannouncements {\n\t\t\tid\n\t\t\ttype\n\t\t\tmessage\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery announcement_announcementQuery {\n\t\tannouncements {\n\t\t\tid\n\t\t\ttype\n\t\t\tmessage\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tmutation auth_signIn($email: String!, $password: String!) {\n\t\tsignIn(email: $email, password: $password, expiration: 3600) {\n\t\t\tok\n\t\t\tresult {\n\t\t\t\ttoken\n\t\t\t}\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tmutation auth_signIn($email: String!, $password: String!) {\n\t\tsignIn(email: $email, password: $password, expiration: 3600) {\n\t\t\tok\n\t\t\tresult {\n\t\t\t\ttoken\n\t\t\t}\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tmutation auth_signOut {\n\t\tsignOut {\n\t\t\tok\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tmutation auth_signOut {\n\t\tsignOut {\n\t\t\tok\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery getMe {\n\t\tme {\n\t\t\tid\n\t\t\tname\n\t\t\temail\n\t\t\torganizations {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tslug\n\t\t\t\tslugAlias\n\t\t\t\tslugPreferred\n\t\t\t\tmetadata {\n\t\t\t\t\tstatus\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery getMe {\n\t\tme {\n\t\t\tid\n\t\t\tname\n\t\t\temail\n\t\t\torganizations {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tslug\n\t\t\t\tslugAlias\n\t\t\t\tslugPreferred\n\t\t\t\tmetadata {\n\t\t\t\t\tstatus\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tmutation auth_resetPassword($token: String!, $password: String!) {\n\t\tresetPassword(token: $token, password: $password) {\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tmutation auth_resetPassword($token: String!, $password: String!) {\n\t\tresetPassword(token: $token, password: $password) {\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tmutation auth_expiredTokenResetPasswordRequest($email: String!) {\n\t\tcreateResetPasswordRequest(email: $email) {\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tmutation auth_expiredTokenResetPasswordRequest($email: String!) {\n\t\tcreateResetPasswordRequest(email: $email) {\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tmutation auth_createResetPassword($email: String!) {\n\t\tcreateResetPasswordRequest(email: $email) {\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tmutation auth_createResetPassword($email: String!) {\n\t\tcreateResetPasswordRequest(email: $email) {\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t}\n\t\t}\n\t}\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;