import { useLocation, useSearchParams } from 'react-router-dom';
import { RouteParamsValidationException } from '../../../shared/fe/routes/RouteParamsValidationException';
import { z } from 'zod';
import * as qs from 'qs';

type UseTypedQueryParamsResult<Data> = [Data, (params: Data) => void];

/**
 * Parses query parameters according to the provided schema and provides a setter. Returns the same tuple as
 * the `React.useState` hook, e.g., `[params, setParams]`.
 *
 * Example for pagination:
 *
 * ```
 * const schema = z.object({ page: z.string().pipe(z.coerce.number()) });
 * const [params, setParams] = useTypedQueryParams(schema);
 * console.log(params.page) // `page` is a number, and its presence in the URL is validated, thus never undefined
 * setParams({ page: params.page + 1 }) // updates the state, and propagates the new page into the URL
 * ```
 *
 * Be aware that your schema should always be of the object type (because query parameters are). Calling the setter will
 * merge the provided parameters with the ones already present in the URL, thus preserving all parameters not related to
 * your schema. This behavior has one caveat, unsetting parameters will not work:
 *
 * ```
 * const [params, setParams] = useTypedQueryParams(schema);
 * setParams({}); // does nothing
 * ```
 *
 * To unset, you have to set the parameter's value to undefined:
 *
 * ```
 * const [params, setParams] = useTypedQueryParams(schema);
 * setParams({ param: undefined }); // removes the parameter from the URL
 * ```
 */
const useTypedQueryParams = <Output, Def extends z.ZodTypeDef>(schema: z.Schema<Output, Def>): UseTypedQueryParamsResult<Output> => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { pathname } = useLocation();

	// decode
	const queryParams = qs.parse(searchParams.toString());

	const setQueryParams = (queryParams: Output) => {
		setSearchParams((previousSearchParams) => {
			const previousQueryParams = qs.parse(previousSearchParams.toString());
			return qs.stringify({ ...previousQueryParams, ...queryParams });
		});
	};

	try {
		// validate
		const parsedQueryParams = schema.parse(queryParams);

		return [parsedQueryParams, setQueryParams];
	} catch (error) {
		if (error instanceof z.ZodError) {
			throw new RouteParamsValidationException(error.message, pathname);
		}

		throw error;
	}
};

export { useTypedQueryParams };
