import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { ThemeMode } from '../types/config';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocalStorage } from '../../../shared/fe/hooks/useLocalStorage';

const LOCAL_STORAGE_THEME_MODE_KEY = 'ood-theme-mode';

enum OodThemeMode {
	LIGHT = 'light',
	DARK = 'dark',
	SYSTEM = 'system',
}

type UseThemeModeResult = Readonly<{
	oodMode: OodThemeMode;
	setOodMode: (mode: OodThemeMode) => void;
	mode: ThemeMode;
}>;

const initialValue: UseThemeModeResult = {
	oodMode: OodThemeMode.SYSTEM,
	setOodMode: (mode: OodThemeMode) => {},
	mode: ThemeMode.LIGHT,
};

const ThemeModeContext = createContext(initialValue);
const ThemeModeProvider: FC<PropsWithChildren> = ({ children }) => {
	const [oodMode, setOodMode] = useLocalStorage(LOCAL_STORAGE_THEME_MODE_KEY, OodThemeMode.SYSTEM);
	const isSystemDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

	const [mode, setMode] = useState(ThemeMode.LIGHT);
	const [isFirstRender, setIsFirstRender] = useState(true);

	useEffect(() => {
		if (isFirstRender) {
			// whenever system mode updates, update application mode too
			setIsFirstRender(false);
		}
		switch (oodMode) {
			case OodThemeMode.LIGHT:
				setMode(ThemeMode.LIGHT);
				break;
			case OodThemeMode.DARK:
				setMode(ThemeMode.DARK);
				break;
			default:
				setMode(isSystemDarkMode ? ThemeMode.DARK : ThemeMode.LIGHT);
				break;
		}
	}, [isSystemDarkMode, oodMode, isFirstRender, setIsFirstRender, mode, setMode]);

	return <ThemeModeContext.Provider value={{ oodMode, setOodMode, mode }}>{children}</ThemeModeContext.Provider>;
};

const useThemeMode = (): UseThemeModeResult => {
	return useContext(ThemeModeContext);
};

export { ThemeModeProvider, useThemeMode };
