import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'simplebar/dist/simplebar.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import config from './config/config';
import { ThemeModeProvider } from './themes/themeModeProvider';
import { initDayjs } from './initDayjs';
import { Userpilot } from 'userpilot';

if (import.meta.env.REACT_APP_COMMIT_HASH != null) {
	console.log(`Commit hash %c${import.meta.env.REACT_APP_COMMIT_HASH}`, 'font-weight: bold');
}

if (import.meta.env.REACT_APP_BUILT_AT != null) {
	console.log(`Build at %c${import.meta.env.REACT_APP_BUILT_AT}`, 'font-weight: bold');
}

Sentry.init(config.sentry);
if (config.userpilotAppToken) {
	Userpilot.initialize(config.userpilotAppToken);
}

initDayjs();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
	<ThemeModeProvider>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</ThemeModeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
