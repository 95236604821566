import { ReactNode, useMemo } from 'react';

// material-ui
import { CssBaseline, StyledEngineProvider, alpha } from '@mui/material';
import { createTheme, ThemeOptions, ThemeProvider, Theme, TypographyVariantsOptions } from '@mui/material/styles';

// project import
import useConfig from '../hooks/useConfig';
import Palette from './palette';
import Typography from './typography';
import CustomShadows from './shadows';

// types
import { CustomShadowProps } from '../types/theme';
import { useThemeMode } from './themeModeProvider';
import { ThemeMode } from '../types/config';
import { getNightOrDayPalette } from '../utils/getNightOrDayPalette';

// types
type ThemeCustomizationProps = {
	children: ReactNode;
};

export default function ThemeCustomization({ children }: ThemeCustomizationProps): ReactNode {
	const { fontFamily } = useConfig();
	const { mode } = useThemeMode();

	const theme: Theme = useMemo<Theme>(() => Palette(mode), [mode]);

	const themeTypography: TypographyVariantsOptions = useMemo<TypographyVariantsOptions>(
		() => Typography(mode, fontFamily, theme),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[mode, fontFamily],
	);
	const themeCustomShadows: CustomShadowProps = useMemo<CustomShadowProps>(() => CustomShadows(theme), [theme]);

	const themeOptions: ThemeOptions = useMemo(
		() => ({
			breakpoints: {
				values: {
					xs: 0,
					sm: 768,
					md: 1024,
					lg: 1266,
					xl: 1440,
				},
			},
			mixins: {
				toolbar: {
					minHeight: 60,
					paddingTop: 8,
					paddingBottom: 8,
				},
			},
			palette: theme.palette,
			customShadows: themeCustomShadows,
			zIndex: {
				drawer: 1049,
				datePicker: 1301,
			},
			typography: themeTypography,
			components: {
				MuiButton: {
					defaultProps: {
						disableElevation: true,
					},
					styleOverrides: {
						contained: {
							textTransform: 'none',
							position: 'relative',
							background:
								mode === ThemeMode.DARK
									? `linear-gradient(262.37deg, #FFCC16 7.25%, rgba(163, 95, 14, 0.640625) 47.66%, rgba(20, 20, 20, 0) 112.96%)`
									: theme.palette.primary.main,
							color: mode === ThemeMode.DARK ? theme.palette.night.A100 : theme.palette.day[800],
							overflow: 'hidden',
							transition: 'opacity 0.2s ease',
							backgroundRepeat: 'no-repeat',
							zIndex: 1,

							':before': {
								content: '""',
								position: 'absolute',
								width: '100%',
								height: '100%',
								display: 'block',
								zIndex: -1,
								inset: 0,
								transition: 'opacity 0.2s ease',
								opacity: 0,
								background:
									mode === ThemeMode.DARK
										? `linear-gradient(262.11deg, rgba(20, 20, 20, 0) -7.62%, rgba(163, 95, 14, 0.640625) 41.06%, #FFCC16 101.06%)`
										: theme.palette.primary.light,
							},

							':hover': {
								background: mode === ThemeMode.DARK ? `unset` : theme.palette.primary.main,
							},

							':hover:before': {
								opacity: 1,
							},

							':active:before': {
								opacity: 0,
							},

							':active': {
								background: mode === ThemeMode.DARK ? theme.palette.primary.darker : theme.palette.primary.dark,
							},

							':disabled': {
								background: theme.palette.primary.main,
								opacity: 0.2,
							},
						},
						outlined: {
							background: 'none',
							border: `1px solid ${mode === ThemeMode.DARK ? theme.palette.night[200] : theme.palette.day[300]}`,
							color: mode === ThemeMode.DARK ? theme.palette.night.A100 : theme.palette.day[800],

							':hover': {
								background: 'none',
								border: `1px solid ${mode === ThemeMode.DARK ? theme.palette.night[300] : theme.palette.day[800]}`,
								color: mode === ThemeMode.DARK ? theme.palette.night.A100 : theme.palette.day[800],
							},
						},
					},
				},
				MuiIconButton: {
					styleOverrides: {
						root: {
							borderRadius: 4,
						},
					},
				},
				MuiButtonBase: {
					styleOverrides: {
						root: {
							'&.MuiTab-root.Mui-selected': {
								color: mode === ThemeMode.DARK ? theme.palette.night[900] : theme.palette.day[700],
							},
						},
					},
				},
				MuiInputBase: {
					styleOverrides: {
						sizeSmall: {
							fontSize: '0.75rem',
						},
					},
				},
				MuiInputLabel: {
					styleOverrides: {
						root: {
							color: theme.palette.grey[600],
						},
						outlined: {
							lineHeight: '0.8em',
							'&.MuiInputLabel-sizeSmall': {
								lineHeight: '1em',
							},
							'&.MuiInputLabel-shrink': {
								background: theme.palette.background.paper,
								padding: '0 8px',
								marginLeft: -6,
								lineHeight: '1.4375em',
							},
						},
					},
				},
				MuiOutlinedInput: {
					styleOverrides: {
						input: {
							padding: '10.5px 14px 10.5px 12px',
						},
						notchedOutline: {
							borderColor: theme.palette.mode === ThemeMode.DARK ? theme.palette.grey[200] : theme.palette.grey[300],
						},
						inputSizeSmall: {
							padding: '7.5px 8px 7.5px 12px',
						},
						inputMultiline: {
							padding: 0,
						},
					},
				},
				MuiTabs: {
					defaultProps: {
						indicatorColor: 'primary',
					},
					styleOverrides: {
						root: {
							'.MuiTab-textColorPrimary': {
								color: theme.palette[getNightOrDayPalette(theme)][500],
								fontWeight: 500,
							},
						},
					},
				},
				MuiLink: {
					styleOverrides: {
						root: {
							color: theme.palette[getNightOrDayPalette(theme)].A100,
						},
					},
				},
				MuiTooltip: {
					styleOverrides: {
						tooltip: {
							// @ts-expect-error
							background: alpha(theme.palette[getNightOrDayPalette(theme)][700], 0.35),
						},
					},
				},
			},
		}),
		[theme, themeTypography, themeCustomShadows, mode],
	);

	const themes: Theme = createTheme(themeOptions);

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={themes}>
				<CssBaseline />
				{children}
			</ThemeProvider>
		</StyledEngineProvider>
	);
}
