import { FC, PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider as OriginalQueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
		},
	},
});

const QueryClientProvider: FC<PropsWithChildren> = ({ children }) => {
	return <OriginalQueryClientProvider client={queryClient}>{children}</OriginalQueryClientProvider>;
};

export { QueryClientProvider };
