import { FC, useEffect } from 'react';
import { useAuth } from '../auth/useAuth';
import { useMe } from '../hooks/useMe';
import { Userpilot } from 'userpilot';
import { useLocation } from 'react-router-dom';
import config from '../config/config';

export const UserpilotIntegration: FC = config.userpilotAppToken
	? () => {
			const { isSignedIn } = useAuth();
			const { data: me } = useMe();
			const location = useLocation();

			useEffect(() => {
				if (isSignedIn && me != null) {
					Userpilot.identify(me.id, {
						name: me.name,
						email: me.email,
						environment: config.environment,
					});
				}
			}, [isSignedIn, me]);

			useEffect(() => {
				Userpilot.reload();
			}, [location]);

			return <></>;
		}
	: () => <></>;
