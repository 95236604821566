import { graphql } from '../gql';
import { useCallback, useContext } from 'react';
import { useApiMutation } from '../query/hooks';
import { SignOutErrorCode } from '../gql/graphql';
import { AuthContext } from '../auth/useAuth';

type OnError = (error?: SignOutErrorCode) => void;

type Result = () => void;

const signOutMutation = graphql(`
	mutation auth_signOut {
		signOut {
			ok
			error {
				code
			}
		}
	}
`);

const useSignOut = (onError: OnError): Result => {
	const { setSignedOut, state } = useContext(AuthContext);

	const { mutate } = useApiMutation(signOutMutation, {
		onSuccess: (data) => {
			if (data.signOut?.error?.code != null) {
				onError(data.signOut.error.code);
				return;
			}

			if (data.signOut?.ok !== true) {
				onError();
				return;
			}
		},
		onError: () => {
			onError();
		},
		onSettled: () => {
			setSignedOut();
		},
		invalidateQueries: false,
	});

	return useCallback(() => {
		if (state.isSignedIn) {
			mutate({});
		} else {
			setSignedOut();
		}
	}, [mutate, setSignedOut, state]);
};

export { useSignOut };
