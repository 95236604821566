import { PaletteThemeProps } from '../../types/theme';
import { PalettesProps } from '@ant-design/colors';
import { PaletteColorOptions } from '@mui/material/styles';

const dayColors: PaletteColorOptions = {
	A100: '#000000',
	A50: '#141414',
	900: '#1F1F1F',
	800: '#262626',
	700: '#434343',
	600: '#595959',
	500: '#8C8C8C',
	400: '#BFBFBF',
	300: '#D9D9D9',
	200: '#F0F0F0',
	100: '#F5F5F5',
	50: '#FAFAFA',
	0: '#FFFFFF',
};

const nightColors: PaletteColorOptions = {
	0: '#000000',
	50: '#141414',
	100: '#1F1F1F',
	200: '#262626',
	300: '#434343',
	400: '#595959',
	500: '#7B7B7B',
	600: '#BFBFBF',
	700: '#D9D9D9',
	800: '#F0F0F0',
	900: '#F5F5F5',
	A50: '#FAFAFA',
	A100: '#FFFFFF',
};

const Default = (colors: PalettesProps): PaletteThemeProps => {
	const { blue, red, gold, cyan, green, grey, orange, magenta, purple, lime, geekblue, yellow } = colors;
	const greyColors: PaletteColorOptions = {
		0: grey[0],
		50: grey[1],
		100: grey[2],
		200: grey[3],
		300: grey[4],
		400: grey[5],
		500: grey[6],
		600: grey[7],
		700: grey[8],
		800: grey[9],
		900: grey[10],
		A50: grey[15],
		A100: grey[11],
		A200: grey[12],
		A400: grey[13],
		A700: grey[14],
		A800: grey[16],
		A900: grey[17],
	};

	const contrastText = '#262626';

	return {
		primary: {
			100: '#FFF3D1',
			lighter: '#FFF3D1',
			200: '#FFD56A',
			light: '#FFD56A',
			400: '#FFCB45',
			main: '#FFCB45',
			dark: '#E6B73E',
			600: '#E6B73E',
			darker: '#7A4F0D',
			800: '#7A4F0D',
			contrastText,
		},
		secondary: {
			lighter: greyColors[100],
			100: greyColors[100],
			200: greyColors[200],
			light: greyColors[300],
			400: greyColors[400],
			main: greyColors[500]!,
			600: greyColors[600],
			dark: greyColors[700],
			800: greyColors[800],
			darker: greyColors[900],
			A100: greyColors[0],
			A200: greyColors.A400,
			A300: greyColors.A700,
			contrastText: greyColors[0],
		},
		error: {
			lighter: red[0],
			light: red[2],
			main: red[4],
			dark: red[7],
			darker: red[9],
			contrastText,
		},
		warning: {
			lighter: gold[0],
			light: gold[3],
			main: gold[5],
			dark: gold[7],
			darker: gold[9],
			contrastText: greyColors[100],
		},
		info: {
			lighter: cyan[0],
			light: cyan[3],
			main: cyan[5],
			dark: cyan[7],
			darker: cyan[9],
			contrastText,
		},
		success: {
			lighter: green[0],
			light: green[3],
			main: green[5],
			dark: green[7],
			darker: green[9],
			contrastText,
		},
		grey: greyColors,
		day: dayColors,
		night: nightColors,
		blue: {
			0: blue[0],
			50: blue[1],
			100: blue[2],
			200: blue[3],
			300: blue[4],
			400: blue[5],
			500: blue[6],
			600: blue[7],
			700: blue[8],
			800: blue[9],
			900: blue[10],
		},
		orange: {
			0: orange[0],
			50: orange[1],
			100: orange[2],
			200: orange[3],
			300: orange[4],
			400: orange[5],
			500: orange[6],
			600: orange[7],
			700: orange[8],
			800: orange[9],
			900: orange[10],
		},
		lime: {
			0: lime[0],
			50: lime[1],
			100: lime[2],
			200: lime[3],
			300: lime[4],
			400: lime[5],
			500: lime[6],
			600: lime[7],
			700: lime[8],
			800: lime[9],
			900: lime[10],
		},
		magenta: {
			0: magenta[0],
			50: magenta[1],
			100: magenta[2],
			200: magenta[3],
			300: magenta[4],
			400: magenta[5],
			500: magenta[6],
			600: magenta[7],
			700: magenta[8],
			800: magenta[9],
			900: magenta[10],
		},
		purple: {
			0: purple[0],
			50: purple[1],
			100: purple[2],
			200: purple[3],
			300: purple[4],
			400: purple[5],
			500: purple[6],
			600: purple[7],
			700: purple[8],
			800: purple[9],
			900: purple[10],
		},
		green: {
			0: green[0],
			50: green[1],
			100: green[2],
			200: green[3],
			300: green[4],
			400: green[5],
			500: green[6],
			600: green[7],
			700: green[8],
			800: green[9],
			900: green[10],
		},
		geekblue: {
			0: geekblue[0],
			50: geekblue[1],
			100: geekblue[2],
			200: geekblue[3],
			300: geekblue[4],
			400: geekblue[5],
			500: geekblue[6],
			600: geekblue[7],
			700: geekblue[8],
			800: geekblue[9],
			900: geekblue[10],
		},
		dustred: {
			0: red[0],
			50: red[1],
			100: red[2],
			200: red[3],
			300: red[4],
			400: red[5],
			500: red[6],
			600: red[7],
			700: red[8],
			800: red[9],
			900: red[10],
		},
		gold: {
			0: gold[0],
			50: gold[1],
			100: gold[2],
			200: gold[3],
			300: gold[4],
			400: gold[5],
			500: gold[6],
			600: gold[7],
			700: gold[8],
			800: gold[9],
			900: gold[10],
		},
		cyan: {
			0: cyan[0],
			50: cyan[1],
			100: cyan[2],
			200: cyan[3],
			300: cyan[4],
			400: cyan[5],
			500: cyan[6],
			600: cyan[7],
			700: cyan[8],
			800: cyan[9],
			900: cyan[10],
		},
		yellow: {
			0: yellow[0],
			50: yellow[1],
			100: yellow[2],
			200: yellow[3],
			300: yellow[4],
			400: yellow[5],
			500: yellow[6],
			600: yellow[7],
			700: yellow[8],
			800: yellow[9],
			900: yellow[10],
		},
	};
};

export default Default;
