import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { GuardProps } from '../../types/auth';
import { useSignOut } from '../../auth/useSignOut';
import { useAuthGuard } from '../../utils/route-guard/useAuthGuard';
import { useIntl } from 'react-intl';
import { useMe } from '../../hooks/useMe';
import { useSnackbar } from '../../store/snackbar';

const AuthGuard: FC<GuardProps> = ({ children }) => {
	const intl = useIntl();
	const navigate = useNavigate();
	const location = useLocation();
	const { pass } = useAuthGuard();
	const { data: me, isLoading } = useMe();
	const { openSnackbar } = useSnackbar();

	const signOut = useSignOut(() => {
		openSnackbar({
			open: true,
			message: 'Unexpected error.',
			variant: 'alert',
			alert: {
				color: 'error',
			},
			close: true,
		});
	});

	useEffect(() => {
		if (pass) {
			const shouldLogoutUser = !isLoading && !Boolean(me);

			if (shouldLogoutUser) {
				openSnackbar({
					open: true,
					message: intl.formatMessage({ id: 'auth-guard.error' }),
					variant: 'alert',
					alert: {
						color: 'error',
					},
					close: true,
				});
				signOut();
			}
			return;
		}

		navigate('auth/login', {
			state: {
				from: location.pathname,
			},
			replace: true,
		});
	}, [pass, signOut, intl, navigate, location, me, isLoading, openSnackbar]);

	return pass ? children : null;
};

export default AuthGuard;
