import { FC, useState } from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { initialEditorConfig } from './initialEditorConfig';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { $convertFromMarkdownString, LINK, ORDERED_LIST, TEXT_FORMAT_TRANSFORMERS, UNORDERED_LIST } from '@lexical/markdown';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { IndentationPlugin } from './plugins/IndentationPlugin';
import { MATCHERS } from './utils/linkMatchers';
import { Box, Stack } from '@mui/material';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';
import { SavePlugin } from './plugins/SavePlugin';
import { BR, LINE_BREAK_FIX, UNDERLINE } from './utils/helpers';
import { UpdateReadonlyContentPlugin } from './plugins/UpdateReadonlyContentPlugin';

type Props = Readonly<{
	readonly?: boolean;
	placeholder?: string;
	state: string | null; // string that is converted to markdown
	setState?: (state: string) => void; // returns markdown string from editor
}>;

export const TRANSFORMERS = [...TEXT_FORMAT_TRANSFORMERS, ORDERED_LIST, UNORDERED_LIST, LINK, BR, LINE_BREAK_FIX, UNDERLINE];

/**
 * Text editor with markdown support.
 * Can be also used as a markdown viewer within widgets. In that case, the `readonly` prop should be set to `true`.
 */
export const Editor: FC<Props> = ({ readonly, placeholder, state, setState }) => {
	const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);

	const onRef = (_floatingAnchorElem: HTMLDivElement | null) => {
		if (_floatingAnchorElem != null) {
			setFloatingAnchorElem(_floatingAnchorElem);
		}
	};

	return (
		<LexicalComposer
			initialConfig={{
				...initialEditorConfig,
				editorState: () => (state ? $convertFromMarkdownString(state, TRANSFORMERS) : null),
				editable: !Boolean(readonly),
			}}
		>
			<Stack gap={0} borderRadius={1} overflow={'hidden'}>
				<Box ref={onRef}>
					<RichTextPlugin
						contentEditable={<ContentEditable />}
						ErrorBoundary={LexicalErrorBoundary}
						placeholder={<div className={'placeholder'}>{placeholder}</div>}
					/>
					<HistoryPlugin />
					<IndentationPlugin />
					<ListPlugin />
					<LinkPlugin />
					<AutoLinkPlugin matchers={MATCHERS} />
					<AutoFocusPlugin />
					<MarkdownShortcutPlugin transformers={TRANSFORMERS} />
					<SavePlugin setState={setState} />
					<UpdateReadonlyContentPlugin state={state} />
					{floatingAnchorElem && <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />}
				</Box>
			</Stack>
		</LexicalComposer>
	);
};
