import { Alert, AlertProps, Box } from '@mui/material';
import { useAuth } from '../auth/useAuth';
import { graphql, DocumentType } from '../gql';
import { useApiQuery, usePublicApiQuery } from '../query/hooks';
import { FC } from 'react';
import { Editor } from '../components/wysiwyg/Editor';

const REFETCH_INTERVAL = 5 * 60 * 1000; // ms

const announcements_announcementQuery = graphql(`
	query announcement_announcementQuery {
		announcements {
			id
			type
			message
		}
	}
`);

type AnnouncementData = DocumentType<typeof announcements_announcementQuery>['announcements'][number];
type AnnouncementType = AnnouncementData['type'];

const mapTypeToSeverity = (type: AnnouncementType): AlertProps['severity'] => {
	switch (type) {
		case 'WARNING':
			return 'warning';
		case 'ERROR':
			return 'error';
		default:
			return 'info';
	}
};

export const Announcement: FC = () => {
	const auth = useAuth();
	const { data: publicData } = usePublicApiQuery(announcements_announcementQuery, {
		enabled: !auth.isSignedIn,
		refetchInterval: REFETCH_INTERVAL,
	});
	const { data } = useApiQuery(announcements_announcementQuery, { enabled: auth.isSignedIn, refetchInterval: REFETCH_INTERVAL });

	const result = publicData || data;

	return result?.announcements.map((announcement) => (
		<Box flexShrink={0} key={announcement.id}>
			<Alert severity={mapTypeToSeverity(announcement.type)}>
				<Editor readonly state={announcement.message} />
			</Alert>
		</Box>
	));
};
