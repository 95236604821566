import { ElementType, Suspense } from 'react';
import Loader from './Loader';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Loadable = (Component: ElementType) => (props: any) => {
	return (
		<Suspense fallback={<Loader />}>
			<Component {...props} />
		</Suspense>
	);
};

export default Loadable;
