/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** DateTime custom scalar type */
  DateTime: { input: string; output: string; }
  /** Json custom scalar type */
  Json: { input: unknown; output: unknown; }
  /** graphql */
  UUID: { input: string; output: string; }
};

export type AddGlobalIdentityRolesError = {
  __typename?: 'AddGlobalIdentityRolesError';
  code: AddGlobalIdentityRolesErrorCode;
  developerMessage: Scalars['String']['output'];
};

export type AddGlobalIdentityRolesErrorCode =
  | 'IDENTITY_NOT_FOUND'
  | 'INVALID_ROLE';

export type AddGlobalIdentityRolesResponse = {
  __typename?: 'AddGlobalIdentityRolesResponse';
  error?: Maybe<AddGlobalIdentityRolesError>;
  ok: Scalars['Boolean']['output'];
  result?: Maybe<AddGlobalIdentityRolesResult>;
};

export type AddGlobalIdentityRolesResult = {
  __typename?: 'AddGlobalIdentityRolesResult';
  identity: Identity;
};

export type AddIdpError = {
  __typename?: 'AddIDPError';
  code: AddIdpErrorCode;
  developerMessage: Scalars['String']['output'];
};

export type AddIdpErrorCode =
  | 'ALREADY_EXISTS'
  | 'INVALID_CONFIGURATION'
  | 'UNKNOWN_TYPE';

export type AddIdpResponse = {
  __typename?: 'AddIDPResponse';
  error?: Maybe<AddIdpError>;
  ok: Scalars['Boolean']['output'];
};

export type AddMailTemplateError = {
  __typename?: 'AddMailTemplateError';
  code: AddMailTemplateErrorCode;
  developerMessage: Scalars['String']['output'];
  /** @deprecated Field no longer supported */
  endUserMessage?: Maybe<Scalars['String']['output']>;
};

export type AddMailTemplateErrorCode =
  | 'INVALID_REPLY_EMAIL_FORMAT'
  | 'MISSING_VARIABLE'
  | 'PROJECT_NOT_FOUND';

export type AddMailTemplateResponse = {
  __typename?: 'AddMailTemplateResponse';
  error?: Maybe<AddMailTemplateError>;
  /** @deprecated Field no longer supported */
  errors: Array<AddMailTemplateError>;
  ok: Scalars['Boolean']['output'];
};

export type AddProjectMemberError = {
  __typename?: 'AddProjectMemberError';
  code: AddProjectMemberErrorCode;
  developerMessage: Scalars['String']['output'];
  /** @deprecated Field no longer supported */
  endUserMessage?: Maybe<Scalars['String']['output']>;
  membershipValidation?: Maybe<Array<MembershipValidationError>>;
};

export type AddProjectMemberErrorCode =
  | 'ALREADY_MEMBER'
  | 'IDENTITY_NOT_FOUND'
  | 'INVALID_MEMBERSHIP'
  | 'PROJECT_NOT_FOUND'
  | 'ROLE_NOT_FOUND'
  | 'VARIABLE_EMPTY'
  | 'VARIABLE_NOT_FOUND';

export type AddProjectMemberResponse = {
  __typename?: 'AddProjectMemberResponse';
  error?: Maybe<AddProjectMemberError>;
  errors: Array<AddProjectMemberError>;
  ok: Scalars['Boolean']['output'];
};

export type Announcement = {
  __typename?: 'Announcement';
  id: Scalars['UUID']['output'];
  message: Scalars['String']['output'];
  type: AnnouncementType;
};

export type AnnouncementType =
  | 'ERROR'
  | 'INFO'
  | 'WARNING';

export type ApiKey = {
  __typename?: 'ApiKey';
  id: Scalars['String']['output'];
  identity: Identity;
};

export type ApiKeyWithToken = {
  __typename?: 'ApiKeyWithToken';
  id: Scalars['String']['output'];
  identity: Identity;
  token?: Maybe<Scalars['String']['output']>;
};

export type ChangeMyPasswordError = {
  __typename?: 'ChangeMyPasswordError';
  code: ChangeMyPasswordErrorCode;
  developerMessage: Scalars['String']['output'];
};

export type ChangeMyPasswordErrorCode =
  | 'INVALID_PASSWORD'
  | 'NOT_A_PERSON'
  | 'NO_PASSWORD_SET'
  | 'TOO_WEAK';

export type ChangeMyPasswordResponse = {
  __typename?: 'ChangeMyPasswordResponse';
  error?: Maybe<ChangeMyPasswordError>;
  ok: Scalars['Boolean']['output'];
};

export type ChangeMyProfileError = {
  __typename?: 'ChangeMyProfileError';
  code: ChangeMyProfileErrorCode;
  developerMessage: Scalars['String']['output'];
};

export type ChangeMyProfileErrorCode =
  | 'EMAIL_ALREADY_EXISTS'
  | 'INVALID_EMAIL_FORMAT'
  | 'NOT_A_PERSON';

export type ChangeMyProfileResponse = {
  __typename?: 'ChangeMyProfileResponse';
  error?: Maybe<ChangeMyProfileError>;
  ok: Scalars['Boolean']['output'];
};

export type ChangePasswordError = {
  __typename?: 'ChangePasswordError';
  code: ChangePasswordErrorCode;
  developerMessage: Scalars['String']['output'];
  /** @deprecated Field no longer supported */
  endUserMessage?: Maybe<Scalars['String']['output']>;
};

export type ChangePasswordErrorCode =
  | 'PERSON_NOT_FOUND'
  | 'TOO_WEAK';

export type ChangePasswordResponse = {
  __typename?: 'ChangePasswordResponse';
  error?: Maybe<ChangePasswordError>;
  /** @deprecated Field no longer supported */
  errors: Array<ChangePasswordError>;
  ok: Scalars['Boolean']['output'];
};

export type ChangeProfileError = {
  __typename?: 'ChangeProfileError';
  code: ChangeProfileErrorCode;
  developerMessage: Scalars['String']['output'];
};

export type ChangeProfileErrorCode =
  | 'EMAIL_ALREADY_EXISTS'
  | 'INVALID_EMAIL_FORMAT'
  | 'PERSON_NOT_FOUND';

export type ChangeProfileResponse = {
  __typename?: 'ChangeProfileResponse';
  error?: Maybe<ChangeProfileError>;
  ok: Scalars['Boolean']['output'];
};

export type CheckResetPasswordTokenCode =
  | 'REQUEST_NOT_FOUND'
  | 'TOKEN_EXPIRED'
  | 'TOKEN_NOT_FOUND'
  | 'TOKEN_USED';

export type CheckResetPasswordTokenResult = {
  __typename?: 'CheckResetPasswordTokenResult';
  code: CheckResetPasswordTokenCode;
};

export type CommonSignInResult = {
  person: Person;
  token: Scalars['String']['output'];
};

export type ConfirmOtpError = {
  __typename?: 'ConfirmOtpError';
  code: ConfirmOtpErrorCode;
  developerMessage: Scalars['String']['output'];
  /** @deprecated Field no longer supported */
  endUserMessage?: Maybe<Scalars['String']['output']>;
};

export type ConfirmOtpErrorCode =
  | 'INVALID_OTP_TOKEN'
  | 'NOT_PREPARED';

export type ConfirmOtpResponse = {
  __typename?: 'ConfirmOtpResponse';
  error?: Maybe<ConfirmOtpError>;
  /** @deprecated Field no longer supported */
  errors: Array<ConfirmOtpError>;
  ok: Scalars['Boolean']['output'];
};

export type CreateApiKeyError = {
  __typename?: 'CreateApiKeyError';
  code: CreateApiKeyErrorCode;
  developerMessage: Scalars['String']['output'];
  /** @deprecated Field no longer supported */
  endUserMessage?: Maybe<Scalars['String']['output']>;
  membershipValidation?: Maybe<Array<MembershipValidationError>>;
};

export type CreateApiKeyErrorCode =
  | 'INVALID_MEMBERSHIP'
  | 'PROJECT_NOT_FOUND'
  | 'ROLE_NOT_FOUND'
  | 'VARIABLE_EMPTY'
  | 'VARIABLE_NOT_FOUND';

export type CreateApiKeyResponse = {
  __typename?: 'CreateApiKeyResponse';
  error?: Maybe<CreateApiKeyError>;
  /** @deprecated Field no longer supported */
  errors: Array<CreateApiKeyError>;
  ok: Scalars['Boolean']['output'];
  result?: Maybe<CreateApiKeyResult>;
};

export type CreateApiKeyResult = {
  __typename?: 'CreateApiKeyResult';
  apiKey: ApiKeyWithToken;
};

export type CreatePasswordResetRequestError = {
  __typename?: 'CreatePasswordResetRequestError';
  code: CreatePasswordResetRequestErrorCode;
  developerMessage: Scalars['String']['output'];
  /** @deprecated Field no longer supported */
  endUserMessage?: Maybe<Scalars['String']['output']>;
};

export type CreatePasswordResetRequestErrorCode =
  | 'PERSON_NOT_FOUND';

export type CreatePasswordResetRequestResponse = {
  __typename?: 'CreatePasswordResetRequestResponse';
  error?: Maybe<CreatePasswordResetRequestError>;
  /** @deprecated Field no longer supported */
  errors: Array<CreatePasswordResetRequestError>;
  ok: Scalars['Boolean']['output'];
};

export type CreateProjectOptions = {
  deployTokenHash?: InputMaybe<Scalars['String']['input']>;
  noDeployToken?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateProjectResponse = {
  __typename?: 'CreateProjectResponse';
  error?: Maybe<CreateProjectResponseError>;
  ok: Scalars['Boolean']['output'];
  result?: Maybe<CreateProjectResult>;
};

export type CreateProjectResponseError = {
  __typename?: 'CreateProjectResponseError';
  code: CreateProjectResponseErrorCode;
  developerMessage: Scalars['String']['output'];
};

export type CreateProjectResponseErrorCode =
  | 'ALREADY_EXISTS'
  | 'INIT_ERROR';

export type CreateProjectResult = {
  __typename?: 'CreateProjectResult';
  deployerApiKey?: Maybe<ApiKeyWithToken>;
};

export type CreateResetPasswordRequestOptions = {
  mailProject?: InputMaybe<Scalars['String']['input']>;
  mailVariant?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSessionTokenError = {
  __typename?: 'CreateSessionTokenError';
  code: CreateSessionTokenErrorCode;
  developerMessage: Scalars['String']['output'];
};

export type CreateSessionTokenErrorCode =
  | 'PERSON_DISABLED'
  | 'UNKNOWN_EMAIL'
  | 'UNKNOWN_PERSON_ID';

export type CreateSessionTokenResponse = {
  __typename?: 'CreateSessionTokenResponse';
  error?: Maybe<CreateSessionTokenError>;
  ok: Scalars['Boolean']['output'];
  result?: Maybe<CreateSessionTokenResult>;
};

export type CreateSessionTokenResult = CommonSignInResult & {
  __typename?: 'CreateSessionTokenResult';
  person: Person;
  token: Scalars['String']['output'];
};

export type DisableApiKeyError = {
  __typename?: 'DisableApiKeyError';
  code: DisableApiKeyErrorCode;
  developerMessage: Scalars['String']['output'];
  /** @deprecated Field no longer supported */
  endUserMessage?: Maybe<Scalars['String']['output']>;
};

export type DisableApiKeyErrorCode =
  | 'KEY_NOT_FOUND';

export type DisableApiKeyResponse = {
  __typename?: 'DisableApiKeyResponse';
  error?: Maybe<DisableApiKeyError>;
  /** @deprecated Field no longer supported */
  errors: Array<DisableApiKeyError>;
  ok: Scalars['Boolean']['output'];
};

export type DisableIdpError = {
  __typename?: 'DisableIDPError';
  code: DisableIdpErrorCode;
  developerMessage: Scalars['String']['output'];
};

export type DisableIdpErrorCode =
  | 'NOT_FOUND';

export type DisableIdpResponse = {
  __typename?: 'DisableIDPResponse';
  error?: Maybe<DisableIdpError>;
  ok: Scalars['Boolean']['output'];
};

export type DisableOtpError = {
  __typename?: 'DisableOtpError';
  code: DisableOtpErrorCode;
  developerMessage: Scalars['String']['output'];
  /** @deprecated Field no longer supported */
  endUserMessage?: Maybe<Scalars['String']['output']>;
};

export type DisableOtpErrorCode =
  | 'OTP_NOT_ACTIVE';

export type DisableOtpResponse = {
  __typename?: 'DisableOtpResponse';
  error?: Maybe<DisableOtpError>;
  /** @deprecated Field no longer supported */
  errors: Array<DisableOtpError>;
  ok: Scalars['Boolean']['output'];
};

export type DisablePersonError = {
  __typename?: 'DisablePersonError';
  code: DisablePersonErrorCode;
  developerMessage: Scalars['String']['output'];
};

export type DisablePersonErrorCode =
  | 'PERSON_ALREADY_DISABLED'
  | 'PERSON_NOT_FOUND';

export type DisablePersonResponse = {
  __typename?: 'DisablePersonResponse';
  error?: Maybe<DisablePersonError>;
  ok: Scalars['Boolean']['output'];
};

export type EnableIdpError = {
  __typename?: 'EnableIDPError';
  code: EnableIdpErrorCode;
  developerMessage: Scalars['String']['output'];
};

export type EnableIdpErrorCode =
  | 'NOT_FOUND';

export type EnableIdpResponse = {
  __typename?: 'EnableIDPResponse';
  error?: Maybe<EnableIdpError>;
  ok: Scalars['Boolean']['output'];
};

export type IdpOptions = {
  autoSignUp?: InputMaybe<Scalars['Boolean']['input']>;
  exclusive?: InputMaybe<Scalars['Boolean']['input']>;
  initReturnsConfig?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IdpOptionsOutput = {
  __typename?: 'IDPOptionsOutput';
  autoSignUp: Scalars['Boolean']['output'];
  exclusive: Scalars['Boolean']['output'];
  initReturnsConfig: Scalars['Boolean']['output'];
};

export type IdpResponseInput = {
  url: Scalars['String']['input'];
};

export type Identity = {
  __typename?: 'Identity';
  apiKey?: Maybe<ApiKey>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  permissions?: Maybe<IdentityGlobalPermissions>;
  person?: Maybe<Person>;
  projects: Array<IdentityProjectRelation>;
  roles?: Maybe<Array<Scalars['String']['output']>>;
};

export type IdentityGlobalPermissions = {
  __typename?: 'IdentityGlobalPermissions';
  canCreateProject: Scalars['Boolean']['output'];
  canDeployEntrypoint: Scalars['Boolean']['output'];
};

export type IdentityProjectRelation = {
  __typename?: 'IdentityProjectRelation';
  memberships: Array<Membership>;
  project: Project;
};

export type IdentityProvider = {
  __typename?: 'IdentityProvider';
  configuration: Scalars['Json']['output'];
  disabledAt?: Maybe<Scalars['DateTime']['output']>;
  options: IdpOptionsOutput;
  slug: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type InitSignInIdpError = {
  __typename?: 'InitSignInIDPError';
  code: InitSignInIdpErrorCode;
  developerMessage: Scalars['String']['output'];
  /** @deprecated Field no longer supported */
  endUserMessage?: Maybe<Scalars['String']['output']>;
};

export type InitSignInIdpErrorCode =
  | 'IDP_VALIDATION_FAILED'
  | 'PROVIDER_NOT_FOUND';

export type InitSignInIdpResponse = {
  __typename?: 'InitSignInIDPResponse';
  error?: Maybe<InitSignInIdpError>;
  /** @deprecated Field no longer supported */
  errors: Array<InitSignInIdpError>;
  ok: Scalars['Boolean']['output'];
  result?: Maybe<InitSignInIdpResult>;
};

export type InitSignInIdpResult = {
  __typename?: 'InitSignInIDPResult';
  authUrl: Scalars['String']['output'];
  idpConfiguration?: Maybe<Scalars['Json']['output']>;
  sessionData: Scalars['Json']['output'];
};

export type InviteError = {
  __typename?: 'InviteError';
  code: InviteErrorCode;
  developerMessage: Scalars['String']['output'];
  /** @deprecated Field no longer supported */
  endUserMessage?: Maybe<Scalars['String']['output']>;
  membershipValidation?: Maybe<Array<MembershipValidationError>>;
};

export type InviteErrorCode =
  | 'ALREADY_MEMBER'
  | 'INVALID_EMAIL_FORMAT'
  | 'INVALID_MEMBERSHIP'
  | 'PROJECT_NOT_FOUND'
  | 'ROLE_NOT_FOUND'
  | 'VARIABLE_EMPTY'
  | 'VARIABLE_NOT_FOUND';

export type InviteMethod =
  | 'CREATE_PASSWORD'
  | 'RESET_PASSWORD';

export type InviteOptions = {
  mailVariant?: InputMaybe<Scalars['String']['input']>;
  method?: InputMaybe<InviteMethod>;
};

export type InviteResponse = {
  __typename?: 'InviteResponse';
  error?: Maybe<InviteError>;
  /** @deprecated Field no longer supported */
  errors: Array<InviteError>;
  ok: Scalars['Boolean']['output'];
  result?: Maybe<InviteResult>;
};

export type InviteResult = {
  __typename?: 'InviteResult';
  isNew: Scalars['Boolean']['output'];
  person: Person;
};

export type MailTemplate = {
  content: Scalars['String']['input'];
  projectSlug?: InputMaybe<Scalars['String']['input']>;
  replyTo?: InputMaybe<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
  type: MailType;
  useLayout?: InputMaybe<Scalars['Boolean']['input']>;
  /** Custom mail variant identifier, e.g. a locale. */
  variant?: InputMaybe<Scalars['String']['input']>;
};

export type MailTemplateData = {
  __typename?: 'MailTemplateData';
  content: Scalars['String']['output'];
  projectSlug?: Maybe<Scalars['String']['output']>;
  replyTo?: Maybe<Scalars['String']['output']>;
  subject: Scalars['String']['output'];
  type: MailType;
  useLayout: Scalars['Boolean']['output'];
  variant?: Maybe<Scalars['String']['output']>;
};

export type MailTemplateIdentifier = {
  projectSlug?: InputMaybe<Scalars['String']['input']>;
  type: MailType;
  variant?: InputMaybe<Scalars['String']['input']>;
};

export type MailType =
  | 'EXISTING_USER_INVITED'
  | 'NEW_USER_INVITED'
  | 'RESET_PASSWORD_REQUEST';

export type MemberOrganization = {
  __typename?: 'MemberOrganization';
  id: Scalars['UUID']['output'];
  isCurrent: Scalars['Boolean']['output'];
  metadata?: Maybe<OrganizationMeta>;
  name?: Maybe<Scalars['String']['output']>;
  roles: Array<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  slugAlias?: Maybe<Scalars['String']['output']>;
  slugPreferred: Scalars['String']['output'];
};

export type MemberPerson = {
  __typename?: 'MemberPerson';
  currentOrganization?: Maybe<MemberOrganization>;
  email: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  organizations: Array<MemberOrganization>;
};

export type MemberType =
  | 'API_KEY'
  | 'PERSON';

export type Membership = {
  __typename?: 'Membership';
  role: Scalars['String']['output'];
  variables: Array<VariableEntry>;
};

export type MembershipInput = {
  role: Scalars['String']['input'];
  variables: Array<VariableEntryInput>;
};

export type MembershipValidationError = {
  __typename?: 'MembershipValidationError';
  code: MembershipValidationErrorCode;
  role: Scalars['String']['output'];
  variable?: Maybe<Scalars['String']['output']>;
};

export type MembershipValidationErrorCode =
  | 'ROLE_NOT_FOUND'
  | 'VARIABLE_EMPTY'
  | 'VARIABLE_INVALID'
  | 'VARIABLE_NOT_FOUND';

export type Mutation = {
  __typename?: 'Mutation';
  changeMyPassword?: Maybe<ChangeMyPasswordResponse>;
  changeMyProfile?: Maybe<ChangeMyProfileResponse>;
  createResetPasswordRequest?: Maybe<CreatePasswordResetRequestResponse>;
  resetPassword?: Maybe<ResetPasswordResponse>;
  signIn?: Maybe<SignInResponse>;
  signOut?: Maybe<SignOutResponse>;
};


export type MutationChangeMyPasswordArgs = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};


export type MutationChangeMyProfileArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateResetPasswordRequestArgs = {
  email: Scalars['String']['input'];
  options?: InputMaybe<CreateResetPasswordRequestOptions>;
};


export type MutationResetPasswordArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationSignInArgs = {
  email: Scalars['String']['input'];
  expiration?: InputMaybe<Scalars['Int']['input']>;
  otpToken?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};


export type MutationSignOutArgs = {
  all?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrganizationLicense =
  | 'expired'
  | 'full'
  | 'trial';

export type OrganizationMeta = {
  __typename?: 'OrganizationMeta';
  createdAt: Scalars['DateTime']['output'];
  hubSpotCompanyId?: Maybe<Scalars['Float']['output']>;
  hubSpotLastSync?: Maybe<Scalars['DateTime']['output']>;
  license: OrganizationLicense;
  status: OrganizationStatus;
};

export type OrganizationStatus =
  | 'Ended'
  | 'Production'
  | 'Trial';

export type Person = {
  __typename?: 'Person';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  identity: Identity;
  name?: Maybe<Scalars['String']['output']>;
  otpEnabled: Scalars['Boolean']['output'];
};

export type PrepareOtpResponse = {
  __typename?: 'PrepareOtpResponse';
  ok: Scalars['Boolean']['output'];
  result?: Maybe<PrepareOtpResult>;
};

export type PrepareOtpResult = {
  __typename?: 'PrepareOtpResult';
  otpSecret: Scalars['String']['output'];
  otpUri: Scalars['String']['output'];
};

export type Project = {
  __typename?: 'Project';
  config: Scalars['Json']['output'];
  id: Scalars['String']['output'];
  members: Array<ProjectIdentityRelation>;
  name: Scalars['String']['output'];
  roles: Array<RoleDefinition>;
  slug: Scalars['String']['output'];
};


export type ProjectMembersArgs = {
  input?: InputMaybe<ProjectMembersInput>;
};

export type ProjectIdentityRelation = {
  __typename?: 'ProjectIdentityRelation';
  identity: Identity;
  memberships: Array<Membership>;
};

export type ProjectMembersFilter = {
  email?: InputMaybe<Array<Scalars['String']['input']>>;
  identityId?: InputMaybe<Array<Scalars['String']['input']>>;
  memberType?: InputMaybe<MemberType>;
  personId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ProjectMembersInput = {
  filter?: InputMaybe<ProjectMembersFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectSecret = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  announcements: Array<Announcement>;
  heartbeat: Scalars['String']['output'];
  me: MemberPerson;
};

export type RemoveGlobalIdentityRolesError = {
  __typename?: 'RemoveGlobalIdentityRolesError';
  code: RemoveGlobalIdentityRolesErrorCode;
  developerMessage: Scalars['String']['output'];
};

export type RemoveGlobalIdentityRolesErrorCode =
  | 'IDENTITY_NOT_FOUND'
  | 'INVALID_ROLE';

export type RemoveGlobalIdentityRolesResponse = {
  __typename?: 'RemoveGlobalIdentityRolesResponse';
  error?: Maybe<RemoveGlobalIdentityRolesError>;
  ok: Scalars['Boolean']['output'];
  result?: Maybe<RemoveGlobalIdentityRolesResult>;
};

export type RemoveGlobalIdentityRolesResult = {
  __typename?: 'RemoveGlobalIdentityRolesResult';
  identity: Identity;
};

export type RemoveMailTemplateError = {
  __typename?: 'RemoveMailTemplateError';
  code: RemoveMailTemplateErrorCode;
  developerMessage: Scalars['String']['output'];
  /** @deprecated Field no longer supported */
  endUserMessage?: Maybe<Scalars['String']['output']>;
};

export type RemoveMailTemplateErrorCode =
  | 'PROJECT_NOT_FOUND'
  | 'TEMPLATE_NOT_FOUND';

export type RemoveMailTemplateResponse = {
  __typename?: 'RemoveMailTemplateResponse';
  error?: Maybe<RemoveMailTemplateError>;
  errors: Array<RemoveMailTemplateError>;
  ok: Scalars['Boolean']['output'];
};

export type RemoveProjectMemberError = {
  __typename?: 'RemoveProjectMemberError';
  code: RemoveProjectMemberErrorCode;
  developerMessage: Scalars['String']['output'];
  /** @deprecated Field no longer supported */
  endUserMessage?: Maybe<Scalars['String']['output']>;
};

export type RemoveProjectMemberErrorCode =
  | 'NOT_MEMBER'
  | 'PROJECT_NOT_FOUND';

export type RemoveProjectMemberResponse = {
  __typename?: 'RemoveProjectMemberResponse';
  error?: Maybe<RemoveProjectMemberError>;
  errors: Array<RemoveProjectMemberError>;
  ok: Scalars['Boolean']['output'];
};

export type ResetPasswordError = {
  __typename?: 'ResetPasswordError';
  code: ResetPasswordErrorCode;
  developerMessage: Scalars['String']['output'];
  /** @deprecated Field no longer supported */
  endUserMessage?: Maybe<Scalars['String']['output']>;
};

export type ResetPasswordErrorCode =
  | 'PASSWORD_TOO_WEAK'
  | 'TOKEN_EXPIRED'
  | 'TOKEN_NOT_FOUND'
  | 'TOKEN_USED';

export type ResetPasswordResponse = {
  __typename?: 'ResetPasswordResponse';
  error?: Maybe<ResetPasswordError>;
  /** @deprecated Field no longer supported */
  errors: Array<ResetPasswordError>;
  ok: Scalars['Boolean']['output'];
};

export type RoleConditionVariableDefinition = RoleVariableDefinition & {
  __typename?: 'RoleConditionVariableDefinition';
  name: Scalars['String']['output'];
};

export type RoleDefinition = {
  __typename?: 'RoleDefinition';
  name: Scalars['String']['output'];
  variables: Array<RoleVariableDefinition>;
};

export type RoleEntityVariableDefinition = RoleVariableDefinition & {
  __typename?: 'RoleEntityVariableDefinition';
  entityName: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type RolePredefinedVariableDefinition = RoleVariableDefinition & {
  __typename?: 'RolePredefinedVariableDefinition';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type RoleVariableDefinition = {
  name: Scalars['String']['output'];
};

export type SetProjectSecretError = {
  __typename?: 'SetProjectSecretError';
  code: SetProjectSecretErrorCode;
  developerMessage: Scalars['String']['output'];
};

export type SetProjectSecretErrorCode =
  | 'PROJECT_NOT_FOUND';

export type SetProjectSecretResponse = {
  __typename?: 'SetProjectSecretResponse';
  error?: Maybe<SetProjectSecretError>;
  ok: Scalars['Boolean']['output'];
};

export type SignInError = {
  __typename?: 'SignInError';
  code: SignInErrorCode;
  developerMessage: Scalars['String']['output'];
  /** @deprecated Field no longer supported */
  endUserMessage?: Maybe<Scalars['String']['output']>;
};

export type SignInErrorCode =
  | 'INVALID_OTP_TOKEN'
  | 'INVALID_PASSWORD'
  | 'NO_PASSWORD_SET'
  | 'OTP_REQUIRED'
  | 'PERSON_DISABLED'
  | 'UNKNOWN_EMAIL';

export type SignInIdpError = {
  __typename?: 'SignInIDPError';
  code: SignInIdpErrorCode;
  developerMessage: Scalars['String']['output'];
  /** @deprecated Field no longer supported */
  endUserMessage?: Maybe<Scalars['String']['output']>;
};

export type SignInIdpErrorCode =
  | 'IDP_VALIDATION_FAILED'
  | 'INVALID_IDP_RESPONSE'
  | 'PERSON_ALREADY_EXISTS'
  | 'PERSON_DISABLED'
  | 'PERSON_NOT_FOUND';

export type SignInIdpResponse = {
  __typename?: 'SignInIDPResponse';
  error?: Maybe<SignInIdpError>;
  /** @deprecated Field no longer supported */
  errors: Array<SignInIdpError>;
  ok: Scalars['Boolean']['output'];
  result?: Maybe<SignInIdpResult>;
};

export type SignInIdpResult = CommonSignInResult & {
  __typename?: 'SignInIDPResult';
  person: Person;
  token: Scalars['String']['output'];
};

export type SignInResponse = {
  __typename?: 'SignInResponse';
  error?: Maybe<SignInError>;
  /** @deprecated Field no longer supported */
  errors: Array<SignInError>;
  ok: Scalars['Boolean']['output'];
  result?: Maybe<SignInResult>;
};

export type SignInResult = CommonSignInResult & {
  __typename?: 'SignInResult';
  person: Person;
  token: Scalars['String']['output'];
};

export type SignOutError = {
  __typename?: 'SignOutError';
  code: SignOutErrorCode;
  developerMessage: Scalars['String']['output'];
  /** @deprecated Field no longer supported */
  endUserMessage?: Maybe<Scalars['String']['output']>;
};

export type SignOutErrorCode =
  | 'NOT_A_PERSON'
  | 'NOT_POSSIBLE_SIGN_OUT_WITH_PERMANENT_API_KEY';

export type SignOutResponse = {
  __typename?: 'SignOutResponse';
  error?: Maybe<SignOutError>;
  /** @deprecated Field no longer supported */
  errors: Array<SignOutError>;
  ok: Scalars['Boolean']['output'];
};

export type SignUpError = {
  __typename?: 'SignUpError';
  code: SignUpErrorCode;
  developerMessage: Scalars['String']['output'];
  /** @deprecated Field no longer supported */
  endPersonMessage?: Maybe<Scalars['String']['output']>;
};

export type SignUpErrorCode =
  | 'EMAIL_ALREADY_EXISTS'
  | 'INVALID_EMAIL_FORMAT'
  | 'TOO_WEAK';

export type SignUpResponse = {
  __typename?: 'SignUpResponse';
  error?: Maybe<SignUpError>;
  /** @deprecated Field no longer supported */
  errors: Array<SignUpError>;
  ok: Scalars['Boolean']['output'];
  result?: Maybe<SignUpResult>;
};

export type SignUpResult = {
  __typename?: 'SignUpResult';
  person: Person;
};

export type UnmanagedInviteOptions = {
  password?: InputMaybe<Scalars['String']['input']>;
  resetTokenHash?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateIdpError = {
  __typename?: 'UpdateIDPError';
  code: UpdateIdpErrorCode;
  developerMessage: Scalars['String']['output'];
};

export type UpdateIdpErrorCode =
  | 'INVALID_CONFIGURATION'
  | 'NOT_FOUND';

export type UpdateIdpResponse = {
  __typename?: 'UpdateIDPResponse';
  error?: Maybe<UpdateIdpError>;
  ok: Scalars['Boolean']['output'];
};

export type UpdateProjectError = {
  __typename?: 'UpdateProjectError';
  code: UpdateProjectErrorCode;
  developerMessage: Scalars['String']['output'];
};

export type UpdateProjectErrorCode =
  | 'PROJECT_NOT_FOUND';

export type UpdateProjectMemberError = {
  __typename?: 'UpdateProjectMemberError';
  code: UpdateProjectMemberErrorCode;
  developerMessage: Scalars['String']['output'];
  /** @deprecated Field no longer supported */
  endUserMessage?: Maybe<Scalars['String']['output']>;
  membershipValidation?: Maybe<Array<MembershipValidationError>>;
};

export type UpdateProjectMemberErrorCode =
  | 'INVALID_MEMBERSHIP'
  | 'NOT_MEMBER'
  | 'PROJECT_NOT_FOUND'
  | 'ROLE_NOT_FOUND'
  | 'VARIABLE_EMPTY'
  | 'VARIABLE_NOT_FOUND';

export type UpdateProjectMemberResponse = {
  __typename?: 'UpdateProjectMemberResponse';
  error?: Maybe<UpdateProjectMemberError>;
  /** @deprecated Field no longer supported */
  errors: Array<UpdateProjectMemberError>;
  ok: Scalars['Boolean']['output'];
};

export type UpdateProjectResponse = {
  __typename?: 'UpdateProjectResponse';
  error?: Maybe<UpdateProjectError>;
  ok: Scalars['Boolean']['output'];
};

export type VariableEntry = {
  __typename?: 'VariableEntry';
  name: Scalars['String']['output'];
  values: Array<Scalars['String']['output']>;
};

export type VariableEntryInput = {
  name: Scalars['String']['input'];
  values: Array<Scalars['String']['input']>;
};

export type Announcement_AnnouncementQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type Announcement_AnnouncementQueryQuery = { __typename?: 'Query', announcements: Array<{ __typename?: 'Announcement', id: string, type: AnnouncementType, message: string }> };

export type Auth_SignInMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type Auth_SignInMutation = { __typename?: 'Mutation', signIn?: { __typename?: 'SignInResponse', ok: boolean, result?: { __typename?: 'SignInResult', token: string } | null, error?: { __typename?: 'SignInError', code: SignInErrorCode } | null } | null };

export type Auth_SignOutMutationVariables = Exact<{ [key: string]: never; }>;


export type Auth_SignOutMutation = { __typename?: 'Mutation', signOut?: { __typename?: 'SignOutResponse', ok: boolean, error?: { __typename?: 'SignOutError', code: SignOutErrorCode } | null } | null };

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = { __typename?: 'Query', me: { __typename?: 'MemberPerson', id: string, name?: string | null, email: string, organizations: Array<{ __typename?: 'MemberOrganization', id: string, name?: string | null, slug: string, slugAlias?: string | null, slugPreferred: string, metadata?: { __typename?: 'OrganizationMeta', status: OrganizationStatus } | null }> } };

export type Auth_ResetPasswordMutationVariables = Exact<{
  token: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type Auth_ResetPasswordMutation = { __typename?: 'Mutation', resetPassword?: { __typename?: 'ResetPasswordResponse', error?: { __typename?: 'ResetPasswordError', code: ResetPasswordErrorCode } | null } | null };

export type Auth_ExpiredTokenResetPasswordRequestMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type Auth_ExpiredTokenResetPasswordRequestMutation = { __typename?: 'Mutation', createResetPasswordRequest?: { __typename?: 'CreatePasswordResetRequestResponse', error?: { __typename?: 'CreatePasswordResetRequestError', code: CreatePasswordResetRequestErrorCode } | null } | null };

export type Auth_CreateResetPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type Auth_CreateResetPasswordMutation = { __typename?: 'Mutation', createResetPasswordRequest?: { __typename?: 'CreatePasswordResetRequestResponse', error?: { __typename?: 'CreatePasswordResetRequestError', code: CreatePasswordResetRequestErrorCode } | null } | null };


export const Announcement_AnnouncementQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"announcement_announcementQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"announcements"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]} as unknown as DocumentNode<Announcement_AnnouncementQueryQuery, Announcement_AnnouncementQueryQueryVariables>;
export const Auth_SignInDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"auth_signIn"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"password"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"signIn"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}},{"kind":"Argument","name":{"kind":"Name","value":"password"},"value":{"kind":"Variable","name":{"kind":"Name","value":"password"}}},{"kind":"Argument","name":{"kind":"Name","value":"expiration"},"value":{"kind":"IntValue","value":"3600"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ok"}},{"kind":"Field","name":{"kind":"Name","value":"result"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"token"}}]}},{"kind":"Field","name":{"kind":"Name","value":"error"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"code"}}]}}]}}]}}]} as unknown as DocumentNode<Auth_SignInMutation, Auth_SignInMutationVariables>;
export const Auth_SignOutDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"auth_signOut"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"signOut"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ok"}},{"kind":"Field","name":{"kind":"Name","value":"error"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"code"}}]}}]}}]}}]} as unknown as DocumentNode<Auth_SignOutMutation, Auth_SignOutMutationVariables>;
export const GetMeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getMe"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"organizations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}},{"kind":"Field","name":{"kind":"Name","value":"slugAlias"}},{"kind":"Field","name":{"kind":"Name","value":"slugPreferred"}},{"kind":"Field","name":{"kind":"Name","value":"metadata"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]}}]}}]} as unknown as DocumentNode<GetMeQuery, GetMeQueryVariables>;
export const Auth_ResetPasswordDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"auth_resetPassword"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"token"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"password"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resetPassword"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"token"},"value":{"kind":"Variable","name":{"kind":"Name","value":"token"}}},{"kind":"Argument","name":{"kind":"Name","value":"password"},"value":{"kind":"Variable","name":{"kind":"Name","value":"password"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"error"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"code"}}]}}]}}]}}]} as unknown as DocumentNode<Auth_ResetPasswordMutation, Auth_ResetPasswordMutationVariables>;
export const Auth_ExpiredTokenResetPasswordRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"auth_expiredTokenResetPasswordRequest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createResetPasswordRequest"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"error"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"code"}}]}}]}}]}}]} as unknown as DocumentNode<Auth_ExpiredTokenResetPasswordRequestMutation, Auth_ExpiredTokenResetPasswordRequestMutationVariables>;
export const Auth_CreateResetPasswordDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"auth_createResetPassword"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createResetPasswordRequest"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"error"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"code"}}]}}]}}]}}]} as unknown as DocumentNode<Auth_CreateResetPasswordMutation, Auth_CreateResetPasswordMutationVariables>;