import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { FC, useEffect } from 'react';
import { $convertToMarkdownString } from '@lexical/markdown';
import { TRANSFORMERS } from '../Editor';

type Props = Readonly<{
	setState?: (state: string) => void;
}>;

/**
 * This plugin is for saving the current editor state converted to markdown.
 * Original plugin for handling on change events did not have the option to convert current state to markdown.
 */
export const SavePlugin: FC<Props> = ({ setState }) => {
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		return editor.registerUpdateListener(({ dirtyElements, dirtyLeaves }) => {
			editor.update(() => {
				// Only call setState if there are dirty elements or leaves
				if (dirtyElements.size > 0 || dirtyLeaves.size > 0) {
					const markdown = $convertToMarkdownString(TRANSFORMERS).replaceAll(/\n{2}/gm, '\n');
					setState?.(markdown);
				}
			});
		});
		// setState is not stable, so we can't add it to the dependency array
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editor]);

	return null;
};
