import { useQueryClient } from '@tanstack/react-query';
import { useAuth } from '../auth/useAuth';
import { useSignOut } from '../auth/useSignOut';
import { DocumentType, graphql } from '../gql';
import { useApiQuery } from '../query/hooks';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSnackbar } from '../store/snackbar';

const getMe = graphql(`
	query getMe {
		me {
			id
			name
			email
			organizations {
				id
				name
				slug
				slugAlias
				slugPreferred
				metadata {
					status
				}
			}
		}
	}
`);

type Me = DocumentType<typeof getMe>['me'];

export type Organizations = NonNullable<Me>['organizations'];

type ReturnType = Readonly<{
	data: Me | undefined;
	isLoading: boolean;
}>;

const useMe = (): ReturnType => {
	const intl = useIntl();
	const { isSignedIn } = useAuth();
	const { data, isLoading, error } = useApiQuery(getMe, { enabled: isSignedIn });
	const queryClient = useQueryClient();
	const { openSnackbar } = useSnackbar();

	const signOut = useSignOut(() =>
		openSnackbar({
			open: true,
			message: intl.formatMessage({ id: 'common.error.default' }),
			variant: 'alert',
			alert: {
				color: 'error',
			},
			close: true,
		}),
	);

	useEffect(() => {
		if (error) {
			signOut();
			return;
		}
	}, [error, signOut, queryClient]);

	return { data: data?.me, isLoading };
};

export { useMe };
