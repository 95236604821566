import request, { type Variables } from 'graphql-request';
import { type TypedDocumentNode } from '@graphql-typed-document-node/core';
import {
	useQuery,
	useMutation,
	type UseQueryResult,
	type UseMutationResult,
	UseQueryOptions,
	useQueryClient,
	type QueryClient,
	UseMutationOptions,
} from '@tanstack/react-query';
import { AuthContext, useAuth } from '../auth/useAuth';
import config from '../config/config';
import { useSnackbar } from '../store/snackbar';
import { useIntl } from 'react-intl';
import { DefinitionNode, OperationDefinitionNode } from 'graphql/language';
import { useContext, useEffect } from 'react';

type CustomUseMutationOptions<R, E, V> = Omit<UseMutationOptions<R, E, V>, 'mutationFn'> & {
	readonly invalidateQueries?: boolean;
};

const isOperationDefinition = (definition: DefinitionNode): definition is OperationDefinitionNode => {
	return definition.kind === 'OperationDefinition';
};

const getDocumentQueryKey = <R, V>(document: TypedDocumentNode<R, V>): string => {
	const operationDefinitions = document.definitions.filter(isOperationDefinition);

	if (operationDefinitions.length !== 1) {
		throw new Error('Expected exactly one operation definition within GraphQL document.');
	}

	if (operationDefinitions[0].name == null) {
		throw new Error('Expected GraphQL operation name to be defined.');
	}

	return operationDefinitions[0].name.value;
};

const getErrorResponseStatus = (error: unknown): number | null => {
	return typeof error === 'object' &&
		error != null &&
		'response' in error &&
		typeof error.response === 'object' &&
		error.response != null &&
		'status' in error.response &&
		typeof error.response.status === 'number'
		? error.response.status
		: null;
};

const onError = (setSignedOut: () => void, error: unknown, queryClient: QueryClient) => {
	if (getErrorResponseStatus(error) === 401) {
		setSignedOut();
		queryClient.clear();
		return;
	}
};

const usePublicApiQuery = <R, E, V extends Variables | undefined>(
	document: TypedDocumentNode<R, V>,
	options?: Omit<UseQueryOptions<R, E, R>, 'queryFn' | 'queryKey'> & { variables?: V },
): UseQueryResult<R, E> => {
	const auth = useAuth();
	const intl = useIntl();
	const { openSnackbar } = useSnackbar();

	const query = useQuery<R, E, R>({
		queryKey: [getDocumentQueryKey(document), options?.variables],
		queryFn: async () => {
			if (auth.isSignedIn) {
				throw new Error('Cannot use usePublicApiQuery hook with a signed in user.');
			}

			return request({
				url: config.apigw.url,
				document,
				variables: options?.variables ?? {},
				requestHeaders: {
					Authorization: `Bearer ${config.contember.loginToken}`,
				},
			});
		},
		...options,
	});

	useEffect(() => {
		if (query.error) {
			openSnackbar({
				open: true,
				message: intl.formatMessage({ id: 'common.error.default' }),
				variant: 'alert',
				alert: {
					color: 'error',
				},
				close: true,
			});
		}
	}, [query.error, intl, openSnackbar]);

	return query;
};

const usePublicApiMutation = <R, E, V extends Variables | undefined>(
	document: TypedDocumentNode<R, V>,
	options?: CustomUseMutationOptions<R, E, V>,
): UseMutationResult<R, E, V> => {
	const auth = useAuth();
	const intl = useIntl();
	const queryClient = useQueryClient();
	const { openSnackbar } = useSnackbar();

	return useMutation({
		mutationFn: async (variables: V) => {
			if (auth.isSignedIn) {
				throw new Error('Cannot use usePublicApiMutation hook with a signed in user.');
			}

			return request({
				url: config.apigw.url,
				document,
				variables,
				requestHeaders: {
					Authorization: `Bearer ${config.contember.loginToken}`,
				},
			});
		},
		onError: () => {
			openSnackbar({
				open: true,
				message: intl.formatMessage({ id: 'common.error.default' }),
				variant: 'alert',
				alert: {
					color: 'error',
				},
				close: true,
			});
		},
		...options,
		onSuccess: async (data, variables, context) => {
			if (options?.invalidateQueries !== false) {
				await queryClient.invalidateQueries();
			}
			await options?.onSuccess?.(data, variables, context);
		},
	});
};

const useApiQuery = <R, E, V extends Variables | undefined>(
	document: TypedDocumentNode<R, V>,
	options?: Omit<UseQueryOptions<R, E, R>, 'queryFn' | 'queryKey'> & { variables?: V; validateResult?: (result: R) => void },
): UseQueryResult<R, E> => {
	const intl = useIntl();
	const queryClient = useQueryClient();
	const { setSignedOut } = useContext(AuthContext);
	const auth = useAuth();
	const { openSnackbar } = useSnackbar();

	const query = useQuery<R, E, R>({
		queryKey: [getDocumentQueryKey(document), options?.variables],
		queryFn: async () => {
			if (!auth.isSignedIn) {
				throw new Error('Cannot use useApiQuery hook without a signed in user.');
			}

			const result = await request({
				url: config.apigw.url,
				document,
				variables: options?.variables ?? {},
				requestHeaders: {
					Authorization: `Bearer ${auth.token}`,
				},
			});

			options?.validateResult?.(result);

			return result;
		},
		...options,
	});

	useEffect(() => {
		if (query.error) {
			openSnackbar({
				open: true,
				message: intl.formatMessage({ id: 'common.error.default' }),
				variant: 'alert',
				alert: {
					color: 'error',
				},
				close: true,
			});
			onError(setSignedOut, query.error, queryClient);
		}
		// omitting setSignedOut from deps as it does not have stable reference
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query.error, intl]);

	return query;
};

const useApiMutation = <R, E, V extends Variables | undefined>(
	document: TypedDocumentNode<R, V>,
	options?: CustomUseMutationOptions<R, E, V>,
): UseMutationResult<R, E, V> => {
	const intl = useIntl();
	const queryClient = useQueryClient();
	const { setSignedOut } = useContext(AuthContext);
	const auth = useAuth();
	const { openSnackbar } = useSnackbar();

	return useMutation({
		mutationFn: async (variables: V) => {
			if (!auth.isSignedIn) {
				throw new Error('Cannot use useApiMutation hook without a signed in user.');
			}

			return request({
				url: config.apigw.url,
				document,
				variables,
				requestHeaders: {
					Authorization: `Bearer ${auth.token}`,
				},
			});
		},
		onError: (error) => {
			openSnackbar({
				open: true,
				message: intl.formatMessage({ id: 'common.error.default' }),
				variant: 'alert',
				alert: {
					color: 'error',
				},
				close: true,
			});
			onError(setSignedOut, error, queryClient);
		},
		...options,
		onSuccess: async (data, variables, context) => {
			if (options?.invalidateQueries !== false) {
				await queryClient.invalidateQueries();
			}
			await options?.onSuccess?.(data, variables, context);
		},
	});
};

export { useApiQuery, useApiMutation, usePublicApiQuery, usePublicApiMutation };
