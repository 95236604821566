import ThemeCustomization from './themes';
import Locales from './components/Locales';
import ScrollTop from './components/ScrollTop';
import Snackbar from './components/@extended/Snackbar';
import { AuthProvider } from './auth/useAuth';
import { QueryClientProvider } from './query/ClientProvider';
import { FC } from 'react';
import { Announcement } from './announcement/Announcement';
import { Stack } from '@mui/material';
import { UserpilotIntegration } from './userpilot/UserpilotIntegration';
import { HelmetWrapper } from './HelmetWrapper';
import 'yet-another-react-lightbox/styles.css';
import { CookiesProvider } from 'react-cookie';
import Routes from './routes';
import { getEnvironmentDomain } from './utils/getEnvironmentDomain';
import { z } from 'zod';
import { useTypedQueryParams } from './hooks/useTypedQueryParams';
import { useHandleGoogleAuthRedirect } from './hooks/useHandleGoogleAuthRedirect';

const inThreeMonths = new Date(Date.now());
inThreeMonths.setMonth(inThreeMonths.getMonth() + 3);

const typesQueryParamsSchema = z.object({
	state: z.string().optional(),
});

const App: FC = () => {
	const [{ state }] = useTypedQueryParams(typesQueryParamsSchema);
	useHandleGoogleAuthRedirect(state);

	return (
		<CookiesProvider defaultSetOptions={{ path: '/', sameSite: 'lax', domain: getEnvironmentDomain(), expires: inThreeMonths }}>
			<ThemeCustomization>
				<Locales>
					<AuthProvider>
						<QueryClientProvider>
							<HelmetWrapper>
								<ScrollTop>
									<Stack minHeight={'100svh'} flexDirection={'column'}>
										<Announcement />
										<Routes />
									</Stack>
									<Snackbar />
								</ScrollTop>
								<UserpilotIntegration />
							</HelmetWrapper>
						</QueryClientProvider>
					</AuthProvider>
				</Locales>
			</ThemeCustomization>
		</CookiesProvider>
	);
};

export default App;
