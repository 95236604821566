import { useState, useEffect } from 'react';

type UseStorageResult<V> = readonly [V, (newValue: V) => void];

/**
 * @deprecated This implementation under certain conditions contains bug. Use `atomWithStorage` with `useAtom` from Jotai instead.
 */
const useLocalStorage = <V>(key: string, defaultValue: V): UseStorageResult<V> => {
	const [value, setValue] = useState<V>(() => {
		const storedValue = localStorage.getItem(key);
		return storedValue == null ? defaultValue : JSON.parse(storedValue);
	});

	useEffect(() => {
		const listener = (event: StorageEvent): void => {
			if (event.storageArea === localStorage && event.key === key) {
				setValue(event.newValue == null ? defaultValue : JSON.parse(event.newValue));
			}
		};

		const additionalListener = () => {
			const storedValue = localStorage.getItem(key);
			setValue(storedValue == null ? defaultValue : JSON.parse(storedValue));
		};

		window.addEventListener('storage', listener);
		window.addEventListener('focus', additionalListener);
		document.addEventListener('visibilitychange', additionalListener);

		return () => {
			window.removeEventListener('storage', listener);
			window.removeEventListener('focus', additionalListener);
			document.removeEventListener('visibilitychange', additionalListener);
		};
	}, [key, defaultValue]);

	return [
		value,
		(newValue: V): void => {
			localStorage.setItem(key, JSON.stringify(newValue));
			setValue(newValue);
		},
	];
};

export { useLocalStorage };
