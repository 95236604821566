import { createContext } from 'react';

// project import
import config from '../config';

// types
import { CustomizationProps, FontFamily, I18n, ThemeMode } from '../types/config';

// initial state
const initialState: CustomizationProps = {
	...config,
	onChangeLocalization: (lang: I18n) => {},
	onChangeMode: (mode: ThemeMode) => {},
	onChangeFontFamily: (fontFamily: FontFamily) => {},
};

// ==============================|| CONFIG CONTEXT & PROVIDER ||============================== //

const ConfigContext = createContext(initialState);

export { ConfigContext };
